import React, { useState } from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoClick = () => {
    setIsVideoPlaying(true);
  };
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh3.jpg')}alt="Urology Hero" />
        <h1>Fistula</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Fistula</h2>
          <img src={require('../images/gastric3.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy views fistula as a pathological connection between two epithelialized surfaces that has formed due to chronic inflammation or infection.</li>
            <li>Treatment aims to reduce inflammation, promote healing, and prevent recurrence through individualized remedies.</li>
            <li>Remedies are selected based on the location of the fistula, discharge characteristics, and overall health of the individual.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Causes of fistula can include chronic infections (such as anal abscesses), inflammatory bowel disease (like Crohn’s disease), trauma, or surgical complications.</li>
            <li>Certain medical conditions predisposing to chronic inflammation and infection may also contribute.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic evaluation typically involves physical examination to locate and assess the fistula tract.</li>
            <li>Imaging studies such as MRI or CT scans may be used to visualize the fistula tract and determine its extent.</li>
            <li>Homeopaths consider the overall health, symptoms, and emotional state of the person in their assessment.</li>
        </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications of fistula can include recurrent infections, abscess formation, and damage to surrounding tissues.</li>
            <li>Risk factors include underlying inflammatory conditions, immune system disorders, and previous surgeries or trauma.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include surgical procedures to excise the fistula tract and promote healing.</li>
            <li>Depending on the complexity and location, procedures such as fistulotomy, seton placement, or advancement flap surgery may be performed.</li>
            <li>Antibiotics may be prescribed to treat or prevent infection.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li>Individualized remedies such as Silicea, Myristica sebifera, Hepar sulphuris, or Graphites may be recommended based on symptomatology.</li>
            <li>Remedies aim to reduce inflammation, promote drainage, and support the healing process.</li>
            <li>Emphasis on dietary recommendations (rich in fiber, adequate hydration) and lifestyle modifications (stress reduction, regular exercise) to support overall health and immune function.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy offers a complementary approach to managing fistula, focusing on reducing inflammation, promoting healing, and preventing recurrence. It can be integrated with conventional treatments under the guidance of a qualified homeopathic practitioner. Personalized treatment plans, including dietary and lifestyle recommendations, are crucial for supporting the healing process and improving overall well-being. Regular monitoring and adjustments in treatment are important to address the specific needs and symptoms of individuals with fistula, promoting long-term relief and preventing complications.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Fistula</h3>
            <p>We offer natural, safe, and effective treatment for Fistula conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/fistula/fistula-diet">Diet for Fistula</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Fistula</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Fistula</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
        <h3>Watch Our Video on Fistula Treatment</h3>
        {!isVideoPlaying ? (
          <img
          src="https://img.youtube.com/vi/881Ui-soo20/hqdefault.jpg"
          alt="Video Thumbnail"
            onClick={handleVideoClick}
            style={{ cursor: 'pointer', width: '50%', height: '400px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
          />
        ) : (
          <iframe
            src="https://www.youtube.com/embed/881Ui-soo20?si=YZSfLZ_J3CFucSRH"
            title="Kidney Stone Treatment Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </div>
  );
};

export default KidneyPage;
