import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh6.jpg')}alt="Urology Hero" />
        <h1>Gastritis</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Gastritis</h2>
          <img src={require('../images/gastric6.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <p>
        Homoeopathy treats gastritis by addressing the person’s overall health, including physical, mental, and emotional aspects. The goal is to identify and treat the root causes of gastritis rather than merely suppressing symptoms.We provide homeopathic remedy for gas are selected based on the individual’s unique symptoms and constitution, aiming to relieve inflammation, restore digestive health, and support long-term wellness.        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Infection:</h5>Helicobacter pylori (H. pylori): A common bacterial infection that damages the stomach lining, leading to inflammation.            </li>
            <li><h5>Excessive Alcohol Consumption:</h5>Alcohol irritates the stomach lining, causing inflammation and increasing the risk of gastritis.            </li>
            <li><h5>Long-term Use of NSAIDs:</h5>Regular use of painkillers such as ibuprofen or aspirin can weaken the stomach lining and cause gastritis.            </li>
            <li><h5>Stress:</h5>Both physical and emotional stress can affect the stomach’s acid production and worsen gastritis.            </li>
            <li><h5>Autoimmune Disorders:</h5>Conditions like pernicious anaemia, where the immune system attacks stomach cells, can lead to chronic gastritis.            </li>
            <li><h5>Dietary Factors:</h5>Spicy, acidic, or fried foods, and beverages like coffee and soda, can irritate the stomach lining, contributing to gastritis.            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Upper Abdominal Pain:</h5>A burning or gnawing pain in the upper stomach, often worsened by eating or on an empty stomach.            </li>
            <li><h5>Nausea and Vomiting:</h5>Persistent nausea or vomiting, sometimes with blood if the stomach lining is severely irritated.            </li>
            <li><h5>Bloating:</h5>A feeling of fullness or swelling in the stomach, often accompanied by belching or gas.            </li>
            <li><h5>Loss of Appetite:</h5>Reduced desire to eat due to discomfort and pain.            </li>
            <li><h5>Indigestion:</h5>Difficulty digesting food, often causing discomfort after meals, heartburn, or acid reflux.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Ulcers:</h5>Chronic inflammation can lead to open sores or ulcers in the stomach lining.            </li>
            <li><h5>Bleeding:</h5>Severe gastritis can cause bleeding, which may lead to anaemia or other serious health issues.            </li>
            <li><h5>Increased Risk of Stomach Cancer:</h5>Long-term gastritis, particularly when caused by H. pylori infection or autoimmune issues, may raise the risk of stomach cancer.            </li>
            <li><h5>Dehydration:</h5>Frequent vomiting associated with gastritis can lead to dehydration if not managed properly.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Antacids and Proton Pump Inhibitors (PPIs):</h5>Medications to reduce stomach acid and alleviate pain and inflammation.            </li>
            <li><h5>Antibiotics:</h5>If H. pylori infection is the cause, a course of antibiotics may be prescribd to eliminate the bacteria. </li>
            <li><h5>Lifestyle Modifications:</h5>Adopting a bland, low-acid diet, reducing stress, avoiding alcohol and NSAIDs, and quitting smoking.            </li>         
            <li><h5>Acid Reducers (H2 Blockers):</h5>Medications to decrease acid production and allow the stomach lining to heal.            </li>
            <li><h5>Vitamin B12 Supplements:</h5>Essential for individuals with gastritis due to autoimmune causes that affect vitamin absorption.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Nux Vomica:</h5>Suitable for individuals with gastritis caused by overindulgence in alcohol, rich foods, or stimulants. It is effective for symptoms like heartburn, bloating, and a sensation of fullness.            </li>
            <li><h5>Arsenicum Album:</h5>Recommended for burning pain in the stomach, nausea, and vomiting. It is beneficial for those who feel worse after eating or drinking, particularly cold foods.            </li>
            <li><h5>Pulsatilla:</h5>Useful for gastritis symptoms that are worse after eating fatty or fried foods, with symptoms of fullness, belching, and nausea.            </li>         
            <li><h5>Carbo Vegetabilis:</h5>Effective for individuals with extreme bloating, gas, and a sensation of heaviness. It is particularly helpful for those who feel better with fresh air.            </li>
            <li><h5>Lycopodium:</h5>Beneficial for individuals with bloating, a feeling of fullness after small meals, and heartburn. It is especially effective when the symptoms worsen in the evening.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy provides relief for individuals with gastritis by focusing on the whole person, not just the symptoms. Many patients report reduced pain, less bloating, and improved digestion with homoeopathic treatment. Homoeopathy not only manages acute symptoms but also aims to prevent recurrence by addressing underlying causes such as stress, dietary habits, and lifestyle factors. While it may not replace conventional treatments in severe cases, homoeopathy serves as a gentle, complementary approach that supports long-term digestive health.          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Gastritis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Gastritis.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/gastritis/gastritis-diet">Diet for preventing Gastritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Gastritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for relief of Gastritis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Gastritis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/fL7IAzcEaVc?si=lFPCvKO0BIEeMVA1"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
