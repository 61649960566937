import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat4.jpg')}alt="Urology Hero" />
        {/* <h1>Rheumatoid Arthritis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Rheumatoid Arthritis</h2>
          <img src={require('../images/joint1.jpg')}alt="Urology Hero" />
          <h3>Causes</h3>
         <p>Rheumatoid arthritis is an autoimmune disease. This means the immune system, which normally fights infection, mistakenly attacks healthy tissue in the joints. The exact cause of this malfunction is unknown. However, researchers believe it’s likely a combination of factors:</p>
          <ul>
            <li><h5>Genetics:</h5>Having certain genes increases susceptibility, but doesn’t guarantee developing RA.</li>
            <li><h5>Sex hormones:</h5>Women are more likely than men to develop RA, suggesting hormones may play a role.</li>
            <li><h5>Environmental Factors: </h5>Smoking is a major risk factor. Other potential triggers include infections and exposure to certain chemicals.</li>
          </ul>
          <h3>Symptoms</h3>
          <ul>
            <li><h5>Joint pain, stiffness, and swelling:</h5>Typically affecting smaller joints in the hands, wrists, and feet, often symmetrical (affecting both sides of the body).</li>
            <li><h5>Morning stiffness:</h5>Lasting for at least 30 minutes after waking up.</li>
            <li><h5>Fatigue and malaise: </h5>Feeling tired and generally unwell.</li>
            <li><h5>Low-grade fever: </h5>May occur in some cases.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Physical Examination: </h5>A doctor will assess your joints for swelling, tenderness, and warmth.</li>
            <li><h5>Blood tests: </h5>Looking for inflammation markers, rheumatoid factor (though not always present), and ruling out other conditions.</li>
            <li><h5>Imaging tests:</h5>X-rays to check for joint damage and MRIs for a more detailed view.</li>
          </ul>
          <h3>Complications</h3>
          <ul>
            <li><h5>Joint damage and deformity: </h5>Chronic inflammation can erode cartilage and bone, leading to joint deformity and disability.</li>
            <li><h5>Increased risk of heart disease: </h5>Inflammation can damage blood vessels and increase the risk of heart attack and stroke.</li>
            <li><h5>Osteoporosis:</h5> Chronic inflammation can weaken bones and increase the risk of fractures.</li>
          </ul>
          <h3>Risk Factors</h3>
          <ul>
            <li><h5>Age: </h5>Most commonly diagnosed between 40-60 years old, but can occur at any age.</li>
            <li><h5>Sex: </h5>Women are two to three times more likely to develop RA than men.</li>
            <li><h5>Family history:</h5>Having a close relative with RA increases your risk.</li>
            <li><h5>Smoking: </h5>Significantly increases the risk and severity of RA.</li>
            <li><h5>Obesity: </h5>Excess weight puts stress on joints and increases inflammation.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>There is no cure for RA, but treatment aims to manage symptoms, slow disease progression, and prevent joint damage. It may involve:</p>
          <ul>
            <li><h5>Medications:</h5>Pain relievers, anti-inflammatory drugs (NSAIDs), disease-modifying antirheumatic drugs (DMARDs) to slow disease progression, and biologics that target specific aspects of the immune system.</li>
            <li><h5>Physical therapy:</h5>Exercises to improve joint flexibility, strength, and range of motion.</li>
            <li><h5>Occupational therapy:</h5> Learning techniques to perform daily activities with less joint strain.</li>
            <li><h5>Lifestyle modifications:</h5>Maintaining a healthy weight, eating a balanced diet, getting enough sleep, and managing stress.</li>
          </ul>
          <h3>It is important to note that I cannot recommend homeopathic treatments.</h3>
          
          <h3>Conclusion</h3>
          <p>Rheumatoid arthritis can be a challenging condition, but with proper diagnosis and treatment, many people can manage their symptoms and maintain a good quality of life. If you experience persistent joint pain, stiffness, or swelling, consult a doctor for evaluation and discuss treatment options.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Rheumatoid Arthritis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Rheumatoid Arthritis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/joint-bone/rheumatoid-arthritis/rheumatoid-arthritis-diet">Diet for Rheumatoid Arthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Rheumatoid Arthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Rheumatoid Arthritis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Rheumatoid Arthritis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/8XG82ohENKc?si=x9lV-sSvHF6bRgbB"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
