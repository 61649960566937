import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/chronic.jpg')}alt="Urology Hero" />
        <h1>Chronic Cystitis Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Chronic Cystitis</h2>
          <img src={require('../images/kidney8.jpg')}alt="Urology Hero" />
          <h3>Causes</h3>
          <ul>
            <li>Chronic renal failure (CRF) typically develops over a long period due to conditions that damage the kidneys. Common causes include:</li>
            <li><h5>Diabetes Mellitus: </h5>Prolonged high blood sugar levels can damage the kidneys’ filtering units (glomeruli).</li>
            <li><h5>Hypertension:</h5>High blood pressure can strain blood vessels in the kidneys, leading to damage over time.</li>
            <li><h5>Glomerulonephritis:</h5> Inflammation of the kidney’s filtering units can impair kidney function.</li>
            <li><h5>Polycystic Kidney Disease:</h5>Inherited disorder where fluid-filled cysts form in the kidneys, leading to gradual loss of function.</li>
            <li><h5>Other Factors: </h5>Autoimmune diseases (e.g., lupus nephritis), kidney infections, prolonged obstruction of the urinary tract, and certain medications can also contribute to CRF.</li>
          </ul>
          <h3>Symptoms:</h3>
          <ul>
            <li><h5>General Symptoms:</h5>Fatigue, weakness, and malaise are common due to decreased kidney function affecting overall energy levels.</li>
            <li><h5>Fluid Retention:</h5>Swelling (edema) in legs, ankles, or around the eyes occurs due to the kidneys’ inability to regulate fluid balance.</li>
            <li><h5>Respiratory Issues: </h5>Shortness of breath can result from fluid buildup in the lungs (pulmonary edema).</li>
            <li><h5>Gastrointestinal Symptoms: </h5>Nausea, vomiting, and loss of appetite may occur due to uremic toxins accumulating in the blood.</li>
            <li><h5>Urinary Changes:</h5>Decreased urine output (oliguria) or increased urination at night (nocturia) may be noted.</li>
            <li><h5>Skin Problems:</h5>Persistent itching (pruritus) can result from waste products accumulating in the bloodstream.</li>
            <li><h5>Musculoskeletal Issues: </h5>Muscle cramps, especially in the legs, can occur due to electrolyte imbalances (e.g., potassium).</li>
          </ul>
          <h3>Investigation and Diagnosis:</h3>
          <ul>
            <li><h5>Blood Tests:</h5>Measurement of serum creatinine, blood urea nitrogen (BUN), and electrolyte levels (potassium, calcium, phosphate).</li>
            <li><h5>Urine Tests:</h5>Examination for proteinuria (protein in the urine) and hematuria (blood in the urine).</li>
            <li><h5>Imaging Studies: </h5>Ultrasound or CT scan to assess kidney size, shape, and any abnormalities.</li>
            <li><h5>Kidney Biopsy:</h5>In some cases, a biopsy may be performed to evaluate the extent and cause of kidney damage.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Anemia:</h5>Decreased production of erythropoietin by the kidneys leads to reduced red blood cell production.</li>
            <li><h5>Bone Disease:</h5>Calcium and phosphate imbalances can cause bone pain and weaken bones (renal osteodystrophy).</li>
            <li><h5>Cardiovascular Disease:</h5>Increased risk of heart attacks, heart failure, and hypertension due to fluid overload and electrolyte imbalances.</li>
            <li><h5>Fluid Overload:</h5>Inability of the kidneys to regulate fluid balance can lead to pulmonary edema and heart failure.</li>
            <li><h5>Increased Infection Risk: </h5>Weekened immune response due to uremia and comorbid conditions increases susceptibility to infections.</li>
          </ul>
          <h3>Traditional Treatment of CRF:</h3>
          <ul>
            <li><h5>Medications:</h5>Homeopathic medicine for cyst & control of blood pressure with angiotensin-converting enzyme (ACE) inhibitors or angiotensin receptor blockers (ARBs). Phosphate binders to manage hyperphosphatemia, and erythropoiesis-stimulating agents to treat anemia.</li>
            <li><h5>Dialysis:</h5>Hemodialysis or peritoneal dialysis to replace kidney function by removing waste products and excess fluid from the blood.</li>
            <li><h5>Kidney Transplantation: </h5>Surgical transplantation of a healthy kidney from a donor to replace a failed kidney.</li>
          </ul>
          <h3>Homeopathic and Complementary Treatments for CRF:</h3>
          <ul>
            <li><h5>Homeopathic Remedies:</h5>Individualized treatments like Arsenicum album for burning pains, Apis mellifica for edema and inflammation, and Cantharis for urinary symptoms, based on symptomatology and patient constitution.</li>
            <li><h5>Dietary Modifications:</h5> Low-sodium and low-protein diets to reduce workload on the kidneys and manage fluid and electrolyte imbalances.</li>
            <li><h5>Herbal Supplements: </h5>Potential use of herbs like astragalus to support kidney function and reduce inflammation.</li>
            <li><h5>Acupuncture and Meditation:</h5>Techniques to manage stress and improve overall well-being, potentially benefiting kidney function indirectly.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Effective management of CRF requires a multidisciplinary approach aimed at slowing disease progression, managing symptoms, and addressing complications.
Integrative therapies, including homeopathy and complementary treatments, can complement conventional medical approaches but should be used under the guidance of healthcare professionals.
Regular monitoring of kidney function and adherence to treatment plans are crucial for improving outcomes and quality of life in patients with CRF.
By integrating these approaches, healthcare providers can optimize CRF management, enhancing patient well-being and minimizing disease-related complications.</p>
          
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Chronic Cystitis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Chronic Cystitis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/chronic-cystitis/chronic-cystitis-diet">Diet for Chronic Cystitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Chronic Cystitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Chronic Cystitis Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Chraonic Cystis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/tOXCcJshCq4?si=2PIF_kCohvyQ8hSH"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
