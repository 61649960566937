import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat5.jpg')}alt="Urology Hero" />
        {/* <h1>Ankylosing spondylitis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Ankylosing spondylitis</h2>
          <img src={require('../images/spinal3.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy approaches AS by focusing on the patient’s symptoms and overall constitution.</li>
            <li>Remedies like Rhus tox are used for stiffness and pain that improve with movement, while Bryonia is suited for pain worsened by movement.</li>
            <li>Individualization is key, with remedies selected based on the unique presentation of each patient.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Ankylosing Spondylitis is believed to have a genetic predisposition, particularly associated with the HLA-B27 gene.</li>
            <li>It involves an autoimmune response where the immune system attacks the joints and spine, causing inflammation and eventual fusion of the vertebrae.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnosis involves a combination of clinical evaluation, imaging studies (such as X-rays and MRI scans to detect sacroiliitis and spinal changes), and blood tests to detect HLA-B27 and markers of inflammation (e.g., ESR, CRP).</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications can include severe spinal stiffness and reduced mobility due to spinal fusion (ankylosis).</li>
            <li>Extra-articular manifestations like uveitis (eye inflammation) and cardiac involvement (aortitis) are also possible.</li>
            <li>Risk factors include a family history of AS and being HLA-B27 positive.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include NSAIDs (such as ibuprofen) to relieve pain and inflammation.</li>
            <li>Physical therapy and regular exercise are crucial to maintain flexibility and prevent deformities.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li>Homeopathic remedies aim to alleviate symptoms and modify the disease process.</li>
            <li>Calcarea carbonica may be used for individuals who are chilly and have a tendency towards joint stiffness.</li>
            <li>Thuja is another remedy considered for those with a strong family history of autoimmune diseases.</li>
            </ul>
          <h3>Conclusion</h3>
          <ul>
            <li>Integrating homeopathic treatment with conventional care can offer a holistic approach to managing AS.</li>
            <li>While homeopathy focuses on symptom relief and improving quality of life, regular monitoring and collaboration with healthcare providers are essential.</li>
            <li>Each patient responds uniquely to treatment, and a tailored approach is necessary for optimal outcomes.</li>
            </ul>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Ankylosing spondylitis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Ankylosing spondylitis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/spinal-disease/ankylosing-spondylitis/ankylosing-spondylitis-diet">Diet for Ankylosing spondylitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Ankylosing spondylitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Ankylosing spondylitis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Ankylosing spondylitis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/dYe0HLl9U2A?si=Z_Vx_3nVxlNS1qjy"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
