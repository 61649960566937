import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/eh3.jpg')}alt="Urology Hero" />
        <h1>DNS (Deviated nasal septum) Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>DNS (Deviated nasal septum)</h2>
          <img src={require('../images/ent3.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy provides a holistic and individualized approach to treating a deviated nasal septum (DNS). The focus is on alleviating symptoms, improving nasal airflow, and addressing the underlying causes without the need for surgery. Remedies are chosen based on the principle of “like cures like,” aiming to stimulate the body’s natural healing mechanisms. This personalized approach considers the patient’s overall health, specific symptoms, and emotional well-being, aiming for long-term relief and an enhanced quality of life.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Congenital Factors:</h5>Some individuals are born with a deviated septum due to abnormal fetal development.</li>
            <li><h5>Trauma or Injury:</h5>Injuries to the nose from accidents, sports, or physical altercations can cause the septum to deviate.</li>
            <li><h5>Developmental Factors: </h5>The septum may deviate as part of the natural growth process during childhood and adolescence.</li>
            <li><h5>Infections: </h5>Recurrent nasal infections can cause inflammation and structural changes leading to septal deviation.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>A detailed assessment of symptoms and previous nasal injuries, along with a physical examination using a nasal speculum to inspect the nasal passages.</li>
            <li><h5>Nasal Endoscopy: </h5>A thin, flexible tube with a camera (endoscope) is used to examine the nasal cavity and assess the extent of the septal deviation.</li>
            <li><h5>Imaging Tests:</h5>CT (computed tomography) scans or X-rays provide detailed images of the nasal structures and the degree of deviation.</li>
            <li><h5>Rhinometry:</h5>Acoustic rhinometry or rhinomanometry measures nasal airflow and resistance, helping to quantify the impact of the deviated septum on breathing.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Nasal Obstruction:</h5>Difficulty breathing through one or both nostrils due to blockage caused by the deviated septum.</li>
            <li><h5>Recurrent Sinusitis: </h5>Blocked nasal passages can lead to poor drainage and recurrent sinus infections.</li>
            <li><h5>Nosebleeds:</h5>The deviated septum can cause dryness and crusting in the nasal passages, leading to frequent nosebleeds.</li>
            <li><h5>Sleep Disturbances:</h5>Nasal obstruction can cause snoring and contribute to sleep apnea, affecting sleep quality.</li>
            <li><h5>Headaches:</h5>Chronic nasal obstruction can lead to facial pain and headaches.</li>
            <li><h5>Risk Factors:</h5>Congenital septal deviation, history of nasal trauma, and conditions causing chronic nasal inflammation such as allergies increase the risk.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Decongestants to reduce nasal congestion, antihistamines to manage allergies, and nasal corticosteroid sprays to reduce inflammation.</li>
            <li><h5>Nasal Strips:</h5>Adhesive strips placed on the nose to help open nasal passages and improve airflow.</li>
            <li><h5>Nasal Irrigation:</h5> Saline rinses to clear nasal passages and reduce congestion.</li>
            <li><h5>Surgery:</h5>Septoplasty to straighten the deviated septum and improve nasal airflow in severe cases where other treatments are ineffective.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li><h5>Calcarea Carbonica: </h5>For nasal obstruction with a tendency to develop frequent colds and general sluggishness.</li>
            <li><h5>Pulsatilla:</h5>Effective for nasal congestion with thick, yellowish-green discharge and a tendency to get worse in warm rooms.</li>
            <li><h5>Silicea: </h5>Used for chronic nasal obstruction with a history of recurrent infections and a sensation of dryness in the nose.</li>
            <li><h5>Natrum Muriaticum: </h5>For nasal blockage accompanied by sneezing and watery discharge, often triggered by exposure to allergens.</li> 
            <li><h5>Kali Bichromicum:</h5>Helpful for nasal obstruction with thick, stringy mucus and sinus pain.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homeopathic treatment for a deviated nasal septum (DNS) focuses on personalized care and holistic management. By addressing both the symptoms and underlying causes, homeopathy supports the body’s natural healing processes and improves overall well-being. Many patients experience significant relief from symptoms, improved nasal airflow, and an enhanced quality of life with consistent homeopathic care. Our experience demonstrates that with appropriate remedy selection and patient compliance, homeopathy can effectively complement conventional treatments. This approach not only alleviates symptoms but also promotes long-term health benefits, emphasizing individualized treatment and lifestyle adjustments. Homeopathy offers a gentle and non-invasive alternative, emphasizing holistic care and restoring balance to promote optimal health and well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>DNS Treatment</h3>
            <p>We offer natural, safe, and effective treatment for DNS conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/ent/dns/dns-diet">Diet for DNS</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of DNS</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for DNS</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on DNS Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/mty_9d_m4jI?si=Csm7cw-XAdYTrjo1"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
