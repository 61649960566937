import React from 'react';
import '../css/Urology.css'; // Include your custom CSS here
import {Link} from 'react-router-dom';
const UrologyPage = () => {
  return (
    <div className="urology-page">
      {/* Hero Image */}
      <div className="uro-hero-image">
        <img src={require('../images/treat2.jpg')}alt="Urology Hero" />
       
      </div>

      {/* Main content section */}
      <div className="uro-content-section">
        {/* Disease Links */}
        <div className="disease-links">
          <h1>Related Female troubles</h1>
          <ul>
            <li><Link to="/treatments/female-troubles/infertility">Infertility</Link></li>
            <li><Link to="/treatments/female-troubles/pcod">PCOD</Link></li>
            <li><Link to="/treatments/female-troubles/uterine-fibroid">Uterine Fibroid</Link></li>
            <li><Link to="/treatments/female-troubles/menstural-disorder">Menstural Disorder</Link></li>
            {/* <li><Link to="/treatments/female-troubles/menstural-disorder" onClick={(e) => e.preventDefault()} style={{ pointerEvents: 'none', color: 'gray' }}>Menstrual Disorder</Link></li> */}
            <li><Link to="/treatments/female-troubles/dysfunctional-uterine-bleeding">Dysfunctional Uterine Bleeding(DUB)</Link></li>
            <li><Link to="/treatments/female-troubles/endometriosis">Endometriosis</Link></li>
            <li><Link to="/treatments/female-troubles/ovarian-cyst">Ovarian cyst</Link></li>
            <li><Link to="/treatments/female-troubles/leukorrhea">Leukorrhea</Link></li>
            <li><Link to="/treatments/female-troubles/polyps">Polyps</Link></li>
            <li><Link to="/treatments/female-troubles/fibroadenoma">Fibroadenoma</Link></li>
            <li><Link to="/treatments/female-troubles/hormonal-imbalance">Hormonal imbalance</Link></li>
            <li><Link to="/treatments/female-troubles/menopausal-syndrome" >Menopausal Syndrome</Link></li>
          </ul>
        </div>

        {/* Right Side Content */}
        <div className="uro-right-content">
          <div className="uro-search-box">
            <h3>Search</h3>
            <div className="uro-search-bar">
              <input type="text" placeholder="Search..." />
              <button>Search</button>
            </div>
          </div>
          <div className="recent-posts">
            <h3>Recent Posts</h3>
            <ul>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Understanding Female problems</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Best Diet for female Health</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>FAQs about Female Diseases</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Treatment Options for female disease</a></li>
            </ul>
          </div>
        </div>
      </div>
       <div className="uro-contact-us-card">
        <h2>Contact Us</h2>
        <p>
          Have any questions about urological health? Feel free to get in touch with us for expert advice, consultation, or to book an appointment.
        </p>
        <p>
        📞 Phone: <a href="tel:+919236185711">+919236185711</a><br />
          📧 Email: <a href="mailto:niramayaforyou@gmail.com">niramayaforyou@gmail.com</a><br />
          📍 Address: 0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar Pradesh, India, 273001
        </p>
        <button className="appointment-btn">Book Appointment</button>
      </div>
    </div>
  );
};

export default UrologyPage;
