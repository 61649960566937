import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh10.jpg')}alt="Urology Hero" />
        <h1>Fibroadenoma Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Fibroadenoma Disorder</h2>
          <img src={require('../images/female10.jpg')}alt="Urology Hero" />
         <h3>Basic Homoeopathic Approach</h3>
         <p>
            Homoeopathy treats ovarian cysts by considering the person’s overall constitution, including their physical, mental, and emotional health. Rather than focusing solely on the cyst itself, the goal is to address the underlying imbalances that may be contributing to cyst formation. Homoeopathic remedies are selected based on the individual’s specific symptoms and characteristics, aiming to restore hormonal balance and support the body’s natural healing processes.
         </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Hormonal Imbalances:</h5>Fluctuations in oestrogen levels, particularly during reproductive years, are thought to contribute to fibroadenoma formation.            </li>
            <li><h5>Genetics:</h5>Family history of fibroadenoma or other breast conditions may increase susceptibility.            </li>
            <li><h5>Diet and Lifestyle:</h5>A high-fat diet, sedentary lifestyle, and exposure to environmental toxins may contribute to the development of fibroadenomas.            </li>
            <li><h5>Reproductive Factors:</h5>Women who start menstruating early or have irregular menstrual cycles may have a higher risk of developing fibroadenomas.            </li>
            <li><h5>Use of Contraceptives:</h5>The use of birth control pills, particularly at a younger age, has been linked to an increased risk of fibroadenoma.            </li>
          </ul>
          <h3>Types of Ovarian Cysts</h3>
          <ul>
            <li><h5>Simple Fibroadenoma:</h5>A benign, non-cancerous tumour that doesn’t increase the risk of breast cancer.            </li>
            <li><h5>Complex Fibroadenoma:</h5>Contains cysts or calcifications and may slightly increase the risk of breast cancer.            </li>
            <li><h5>Giant Fibroadenoma:</h5>Grows larger than 2 inches, causing noticeable asymmetry in breast size.            </li>
            <li><h5>Juvenile Fibroadenoma:</h5>Found in adolescents, usually growing rapidly but often shrinking or disappearing over time.
            </li>
          </ul>
          <h3>Investigations:</h3>
          <ul>
            <li><h5>Breast Ultrasound:</h5>Used to assess the size, shape, and characteristics of the fibroadenoma, especially in women under 40.            </li>
            <li><h5>Mammogram:</h5>An X-ray of the breast to identify and evaluate any abnormal lumps, especially in older women.            </li>
            <li><h5>Fine-Needle Aspiration (FNA):</h5>A small needle is used to extract tissue from the lump for analysis to confirm whether it’s benign.            </li>
            <li><h5>Core Needle Biopsy:</h5>Involves a larger needle to remove a small tissue sample, providing more information on the nature of the fibroadenoma.            </li>
            <li><h5>MRI:</h5>Used when there is uncertainty in diagnosis or a high risk of breast cancer, especially in cases of complex fibroadenoma.</li>
          </ul>
          <h3>Complications and Risk Factors:</h3>
          <ul>
            <li><h5>Discomfort:</h5>While usually painless, larger fibroadenomas can cause discomfort or pain due to pressure on surrounding tissue.            </li>
            <li><h5>Increased Cancer Risk:</h5>Complex fibroadenomas can slightly increase the risk of breast cancer, though simple fibroadenomas generally do not.            </li>
            <li><h5>Emotional Distress:</h5>The presence of a breast lump, even a benign one, can cause anxiety and stress.            </li>
            <li><h5>Asymmetry:</h5>Large fibroadenomas can cause noticeable differences in breast size, leading to cosmetic concerns.            </li>
          </ul>
          <h3>Traditional Basic Treatment:          </h3>
          <ul>
            <li><h5>Watchful Waiting:</h5>Since fibroadenomas often remain stable or shrink, doctors may recommend monitoring rather than immediate treatment.            </li>
            <li><h5>Surgical Removal:</h5>Lumpectomy or excisional biopsy may be considered if the fibroadenoma is large, growing, or causing significant discomfort.            </li>
            <li><h5>Cryoablation:</h5>A minimally invasive procedure that uses extreme cold to destroy the fibroadenoma without surgery.            </li>
            <li><h5>Lifestyle Modifications:</h5>Maintaining a balanced diet, avoiding hormone-related products, and reducing alcohol intake may help in managing symptoms and preventing recurrence.            </li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Conium Maculatum:</h5>Often recommended for hard, painful lumps in the breast, especially for women experiencing hormonal changes related to age.            </li>
            <li><h5>Calcarea Fluorica:</h5>Used for hard, stony lumps, often accompanied by a feeling of heaviness. It’s especially useful in cases where there is calcification within the fibroadenoma.            </li>
            <li><h5>Phytolacca Decandra:</h5>Effective for painful, tender lumps and fibrous growths in the breast, particularly when accompanied by shooting pains.            </li>
            <li><h5>Silicea:</h5>Recommended for lumps that are hard and painless. It can also help in cases where the fibroadenoma has formed scar tissue or has calcified.            </li>
            <li><h5>Bellis Perennis:</h5>Known for its action on bruised and sore tissues, this remedy can be beneficial for fibroadenomas following trauma or injury to the breast.            </li>
          </ul>
          <h3>Conclusion</h3>
          <p>In our experience, homoeopathy offers a holistic and supportive approach for women with fibroadenomas. Many patients report reduced lump size, pain relief, and improved emotional well-being. While homoeopathy does not replace conventional treatments, it serves as an effective complementary option, especially for those looking for natural, non-invasive methods to manage their condition. With a focus on treating the whole person, homoeopathic remedies aim to restore balance, prevent recurrence, and support long-term breast health.          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Fibroadenoma Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Fibroadenoma conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/fibroadenoma/fibroadenoma-diet">Diet for relief of Fibroadenoma disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Fibroadenoma </a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Fibroadenoma Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Infertility Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/f7wFw8nJWX8?si=bHew2vT0AFTpy0i5"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};

export default KidneyPage;
