import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from './components/Navbar';
import Footer from './components/Footer';  
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs'; 
import Treatments from './components/Treatments'; 
import Urology from './components/Urology'; 
import Kidney from './components/Kidney'; 
import UrethralStricture from './components/UrethralStricture'; 
import Bph from './components/Bph'; 
import BladderPolyps from './components/BladderPolyps'; 
import OveractiveBladder from './components/OveractiveBladder'; 
import Uti from './components/Uti'; 
import CrfManagement from './components/CrfManagement'; 
import ChronicCystitis from './components/ChronicCystitis'; 
import AcuteRenalFailure from './components/AcuteRenalFailure'; 
import FemaleTroubles from './components/FemaleTroubles'; 
import Infertility from './components/Infertility'; 
import Pcod from './components/Pcod'; 
import UterineFibroid from './components/UterineFibroid'; 
import MensturalDisorder from './components/MensturalDisorder'; 
import DysfunctionalUterineBleeding from './components/DysfunctionalUterineBleeding'; 
import Endometriosis from './components/Endometriosis'; 
import Leukorrhea from './components/Leukorrhea'; 
import Polyps from './components/Polyps';
import OvarianCyst from './components/OvarianCyst'; 
import Fibroadenoma from './components/Fibroadenoma'; 
import HormonalImbalance from './components/HormonalImbalance'; 
import MenopausalSyndrome from './components/MenopausalSyndrome'; 
import Constipation from './components/Constipation'; 
import Hemorrhoids from './components/Hemorrhoids'; 
import Fistula from './components/Fistula'; 
import RecurrentAphthous from './components/RecurrentAphthous'; 
import JointBone from './components/JointBone'; 
import RheumatoidArthritis from './components/RheumatoidArthritis'; 
import Polyarthritis from './components/Polyarthritis'; 
import GoutyArthritis from './components/GoutyArthritis'; 
import Osteoarthritis from './components/Osteoarthritis'; 
import Osteoporosis from './components/Osteoporosis'; 
import ReactiveArthritis from './components/ReactiveArthritis'; 
import SpinalDisease from './components/SpinalDisease'; 
import CervicalSpondylitis from './components/CervicalSpondylitis'; 
import LumbarSpondylosis from './components/LumbarSpondylosis'; 
import AnkylosingSpondylitis from './components/AnkylosingSpondylitis'; 
import Head from './components/Head'; 
import Migraine from './components/Migraine'; 
import ClassicalHeadaches from './components/ClassicalHeadaches'; 
import BrainTumor from './components/BrainTumor'; 
import Ent from './components/Ent'; 
import AllergicRhinitis from './components/AllergicRhinitis'; 
import NasalPolyps from './components/NasalPolyps'; 
import Dns from './components/Dns'; 
import Tonsillitis from './components/Tonsillitis'; 
import Adenoids from './components/Adenoids'; 
import EarPerforation from './components/EarPerforation'; 
import AsomCsom from './components/AsomCsom'; 
import MeniereDisease from './components/MeniereDisease'; 
import HormonalDiseases from './components/HormonalDiseases'; 
import Thyroid from './components/Thyroid'; 
import DiabetesMellitus from './components/DiabetesMellitus'; 
import GrowthHormoneDeficiency from './components/GrowthHormoneDeficiency'; 
import Hyperthyroidism from './components/Hyperthyroidism'; 
import Hypothyroidism from './components/Hypothyroidism'; 
import RecurrentCough from './components/RecurrentCough';
import Pediatric from './components/Pediatric'; 
import BehaviourDisorder from './components/BehaviourDisorder'; 
import HyperactivityDisorder from './components/HyperactivityDisorder'; 
import AutismSpectrumDisorder from './components/AutismSpectrumDisorder'; 
import DevelopmentalDisorder from './components/DevelopmentalDisorder'; 
import GastricTrouble from './components/GastricTrouble'; 
import RecurrentPneumonia from './components/RecurrentPneumonia'; 
import AnalFissures from './components/AnalFissures'; 
import Gastritis from './components/Gastritis'; 
import Irf from './components/Irf'; 
import Gallstone from './components/Gallstone'; 
import LiverAbscess from './components/LiverAbscess'; 
import FattyLiver from './components/FattyLiver'; 
import Appendicitis from './components/Appendicitis'; 
import GastricIssues from './components/GastricIssues'; 
import Psychiatric from './components/Psychiatric'; 
import MoodDisorders from './components/MoodDisorders'; 
import Depression from './components/Depression'; 
import Dementia from './components/Dementia'; 
import PersonalityDisorder from './components/PersonalityDisorder'; 
import AnxietyDisorder from './components/AnxietyDisorder'; 
import ObsessivecompulsiveDisorder from './components/ObsessivecompulsiveDisorder'; 
import PanicDisorder from './components/PanicDisorder'; 
import Phobia from './components/Phobia'; 
import Skin from './components/Skin';
import MolluscumContagiosum from './components/MolluscumContagiosum'; 
import LichenPlanus from './components/LichenPlanus'; 
import HairFall from './components/HairFall'; 
import Psoriasis from './components/Psoriasis'; 
import Vitiligo from './components/Vitiligo'; 
import NailsDisorder from './components/NailsDisorder'; 
import Chloasma from './components/Chloasma'; 
import ContactDermatitis from './components/ContactDermatitis'; 
import Urticaria from './components/Urticaria'; 
import Keloid from './components/Keloid'; 
import Acne from './components/Acne'; 
import Alopecia from './components/Alopecia'; 
import MaleGenital from './components/MaleGenital'; 
import Varicocele from './components/Varicocele';
import Impotence from './components/Impotence'; 
import InfertilityMale from './components/InfertilityMale'; 
import GenitalCyst from './components/GenitalCyst'; 
import Epididymitis from './components/Epididymitis'; 
import MildHydrocele from './components/MildHydrocele'; 
import ContactUs from './components/ContactUs'; 
import Feedback from './components/Feedback'; 
import BookAppointment from './components/BookAppointment'; 
import Queries from './components/Queries'; 
import Blogs from './components/Blogs'; 
import CaseStudies from './components/CaseStudies.js'; 
import VideoCaseStudies from './components/VideoCaseStudies.js'; 
import ForPatient from './components/ForPatient.js'; 
// Urology Diet section
import KidneyDiet from './components/Diet/KidneyDiet'; 
import UrethralStrictureDiet from './components/Diet/UrethralStrictureDiet'; 
import BphDiet from './components/Diet/BphDiet'; 
import BladderPolypsDiet from './components/Diet/BladderPolypsDiet'; 
import OverActiveBladderDiet from './components/Diet/OverActiveBladderDiet'; 
import UtiDiet from './components/Diet/UtiDiet'; 
import CrfManagementDiet from './components/Diet/CrfManagementDiet'; 
import ChronicCystitisDiet from './components/Diet/ChronicCystitisDiet'; 
import AcuteRenalFailureDiet from './components/Diet/AcuteRenalFailureDiet'; 
//Female Troubles Diet Section
import InfertilityDiet from './components/Diet/InfertilityDiet'; 
import PcodDiet from './components/Diet/PcodDiet'; 
import UterineFibroidDiet from './components/Diet/UterineFibroidDiet'; 
import MensturalDisorderDiet from './components/Diet/MensturalDisorderDiet'; 
import DysfunctionalUterineBleedingDiet from './components/Diet/DysfunctionalUterineBleedingDiet'; 
import EndometriosisDiet from './components/Diet/EndometriosisDiet'; 
import OvarianCystDiet from './components/Diet/OvarianCystDiet'; 
import LeukorrheaDiet from './components/Diet/LeukorrheaDiet'; 
import PolypsDiet from './components/Diet/PolypsDiet'; 
import FibroadenomaDiet from './components/Diet/FibroadenomaDiet'; 
import HormonalImbalanceDiet from './components/Diet/HormonalImbalanceDiet'; 
import MenopausalSyndromeDiet from './components/Diet/MenopausalSyndromeDiet'; 
//Gastric diet section
import ConstipationDiet from './components/Diet/ConstipationDiet'; 
import HemorrhoidsDiet from './components/Diet/HemorrhoidsDiet'; 
import FistulaDiet from './components/Diet/FistulaDiet'; 
import RecurrentAphthousDiet from './components/Diet/RecurrentAphthousDiet'; 
import AnalFissuresDiet from './components/Diet/FistulaDiet'; 
import GastritisDiet from './components/Diet/GastritisDiet'; 
import IrfDiet from './components/Diet/IrfDiet'; 
import GallstoneDiet from './components/Diet/GallstoneDiet'; 
import LiverAbscessDiet from './components/Diet/LiverAbscessDiet'; 
import FattyLiverDiet from './components/Diet/FattyLiverDiet'; 
import AppendicitisDiet from './components/Diet/AppendicitisDiet'; 
//skin diet Section
import MolluscumContagiosumDiet from './components/Diet/MolluscumContagiosumDiet'; 
import LichenPlanusDiet from './components/Diet/LichenPlanusDiet'; 
import HairFallDiet from './components/Diet/HairFallDiet'; 
import AcneDiet from './components/Diet/AcneDiet'; 
import AlopeciaDiet from './components/Diet/AlopeciaDiet'; 
import PsoriasisDiet from './components/Diet/PsoriasisDiet'; 
import VitiligoDiet from './components/Diet/VitiligoDiet'; 
import NailsDisorderDiet from './components/Diet/NailsDisorderDiet'; 
import ChloasmaDiet from './components/Diet/ChloasmaDiet'; 
import ContactDermatitisDiet from './components/Diet/ContactDermatitisDiet'; 
import UrticariaDiet from './components/Diet/UrticariaDiet'; 
import KeloidDiet from './components/Diet/KeloidDiet'; 
//Hormonal Disorder diet
import Thyroiddiet from './components/Diet/Thyroiddiet'; 
import DiabetesMellitusDiet from './components/Diet/DiabetesMellitusDiet'; 
import GrowthHormoneDeficiencyDiet from './components/Diet/GrowthHormoneDeficiencyDiet'; 
import HyperthyroidismDiet from './components/Diet/HyperthyroidismDiet'; 
import HypothyroidismDiet from './components/Diet/HypothyroidismDiet'; 
//head diet
import MigraineDiet from './components/Diet/MigraineDiet'; 
import HeadachesDiet from './components/Diet/HeadachesDiet'; 
import BrainTumorDiet from './components/Diet/BrainTumorDiet'; 
//ent diet
import AllergicRhinitisDiet from './components/Diet/AllergicRhinitisDiet'; 
import NasalPolypsDiet from './components/Diet/NasalPolypsDiet'; 
import DnsDiet from './components/Diet/DnsDiet'; 
import TonsillitisDiet from './components/Diet/TonsillitisDiet'; 
import AdenoidsDiet from './components/Diet/AdenoidsDiet'; 
import EarPerforationDiet from './components/Diet/EarPerforationDiet'; 
import AsomCsomDiet from './components/Diet/AsomCsomDiet'; 
import MeniereDiseaseDiet from './components/Diet/MeniereDiseaseDiet'; 
//male- genital diet 
import VaricoceleDiet from './components/Diet/VaricoceleDiet'; 
import ImpotenceDiet from './components/Diet/ImpotenceDiet'; 
import InfertilityMaleDiet from './components/Diet/InfertilityMaleDiet'; 
import GenitalCystDiet from './components/Diet/GenitalCystDiet'; 
import EpididymitisDiet from './components/Diet/EpididymitisDiet'; 
import MildHydroceleDiet from './components/Diet/MildHydroceleDiet'; 
//psychtric diet 
import MoodDisordersDiet from './components/Diet/MoodDisordersDiet'; 
import DepressionDiet from './components/Diet/DepressionDiet'; 
import DementiaDiet from './components/Diet/DementiaDiet'; 
import PersonalityDisorderDiet from './components/Diet/PersonalityDisorderDiet'; 
import AnxietyDisorderDiet from './components/Diet/AnxietyDisorderDiet'; 
import OcdDiet from './components/Diet/OcdDiet'; 
import PanicDisorderDiet from './components/Diet/PanicDisorderDiet'; 
import PhobiaDiet from './components/Diet/PhobiaDiet'; 
//child diet 
import RecurrentCoughColdDiet from './components/Diet/RecurrentCoughColdDiet'; 
import BehaviourDisorderDiet from './components/Diet/BehaviourDisorderDiet'; 
import AttentionDeficitDiet from './components/Diet/AttentionDeficitDiet'; 
import AutismSpectrumDisorderDiet from './components/Diet/AutismSpectrumDisorderDiet'; 
import DevelopmentalDisorderDiet from './components/Diet/DevelopmentalDisorderDiet'; 
import GastricTroubleDiet from './components/Diet/GastricTroubleDiet'; 
import RecurrentPneumoniaDiet from './components/Diet/RecurrentPneumoniaDiet'; 
//spinal diet 
import CervicalSpondylitisDiet from './components/Diet/CervicalSpondylitisDiet'; 
import LumbarSpondylosisDiet from './components/Diet/LumbarSpondylosisDiet'; 
import AnkylosingSpondylitisDiet from './components/Diet/AnkylosingSpondylitisDiet'; 
//joint & bone diet 
import RheumatoidArthritisDiet from './components/Diet/RheumatoidArthritisDiet'; 
import GoutyArthritisDiet from './components/Diet/GoutyArthritisDiet'; 
import OsteoarthritisDiet from './components/Diet/OsteoarthritisDiet'; 
import PolyarthritisDiet from './components/Diet/PolyarthritisDiet'; 
import OsteoporosisDiet from './components/Diet/OsteoporosisDiet'; 
import ReactiveArthritisDiet from './components/Diet/ReactiveArthritisDiet'; 
//blog section
import Homeopathy from './components/Blogs/Homeopathy'; 
import Founder from './components/Blogs/Founder'; 
import HomeopathyInfo from './components/Blogs/HomeopathyInfo'; 
import MentalHealth from './components/Blogs/MentalHealth'; 
import ModernHomeopathy from './components/Blogs/ModernHomeopathy'; 
import HomeopathyVsAllopathy from './components/Blogs/HomeopathyVsAllopathy'; 
import BoostImmunity from './components/Blogs/BoostImmunity'; 
import Digestive from './components/Blogs/Digestive'; 
import PediatricCare from './components/Blogs/PediatricCare'; 
import SeasonalAllergies from './components/Blogs/SeasonalAllergies'; 
import JointPain from './components/Blogs/JointPain'; 
import Myth from './components/Blogs/Myth'; 
//case studies
import CaseKidney from './components/Case/CaseKidney'; 
import CaseUrethral from './components/Case/CaseUrethral'; 
import CaseAlopecia from './components/Case/CaseAlopecia'; 
import CaseArthritis from './components/Case/CaseArthritis'; 
import CaseSkin from './components/Case/CaseSkin'; 
import CaseHormones from './components/Case/CaseHormones'; 
import CaseMigraine from './components/Case/CaseMigraine'; 
import CaseFertility from './components/Case/CaseFertility'; 
import CaseEnt from './components/Case/CaseEnt'; 
import CaseOldAge from './components/Case/CaseOldAge'; 
import CaseFemale from './components/Case/CaseFemale'; 
import CaseMale from './components/Case/CaseMale'; 



import ScrollToTop from './Scroll.js';
const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/treatments" element={<Treatments />} />
        <Route exact path="/treatments/urology" element={<Urology />} />
        <Route exact path="/treatments/urology/kidney-stone" element={<Kidney />} />
        <Route exact path="/treatments/urology/urethral-stricture" element={<UrethralStricture />} />
        <Route exact path="/treatments/urology/bph-prostate" element={<Bph/>} />
        <Route exact path="/treatments/urology/bladder-polyps" element={<BladderPolyps/>} />
        <Route exact path="/treatments/urology/overactive-bladder" element={<OveractiveBladder/>} />
        <Route exact path="/treatments/urology/uti" element={<Uti/>} />
        <Route exact path="/treatments/urology/crf-management" element={<CrfManagement/>} />
        <Route exact path="/treatments/urology/chronic-cystitis" element={<ChronicCystitis/>} />
        <Route exact path="/treatments/urology/acute-renal-failure" element={<AcuteRenalFailure/>} />
        <Route exact path="/treatments/female-troubles" element={<FemaleTroubles />} />
        <Route exact path="/treatments/female-troubles/infertility" element={<Infertility/>} />
        <Route exact path="/treatments/female-troubles/pcod" element={<Pcod/>} />
        <Route exact path="/treatments/female-troubles/uterine-fibroid" element={<UterineFibroid/>} />
        <Route exact path="/treatments/female-troubles/menstural-disorder" element={<MensturalDisorder/>} />
        <Route exact path="/treatments/female-troubles/dysfunctional-uterine-bleeding" element={<DysfunctionalUterineBleeding/>} />
        <Route exact path="/treatments/female-troubles/endometriosis" element={<Endometriosis/>} />
        <Route exact path="/treatments/female-troubles/leukorrhea" element={<Leukorrhea/>} />
        <Route exact path="/treatments/female-troubles/polyps" element={<Polyps/>} />
        <Route exact path="/treatments/female-troubles/ovarian-cyst" element={<OvarianCyst/>} />
        <Route exact path="/treatments/female-troubles/fibroadenoma" element={<Fibroadenoma/>} />
        <Route exact path="/treatments/female-troubles/hormonal-imbalance" element={<HormonalImbalance/>} />
        <Route exact path="/treatments/female-troubles/menopausal-syndrome" element={<MenopausalSyndrome/>} />
        <Route exact path="/treatments/gastric-issues/constipation" element={<Constipation/>} />
        <Route exact path="/treatments/gastric-issues/hemorrhoids" element={<Hemorrhoids/>} />
        <Route exact path="/treatments/gastric-issues/fistula" element={<Fistula/>} />
        <Route exact path="/treatments/gastric-issues/recurrent-aphthous" element={<RecurrentAphthous/>} />
        <Route exact path="/treatments/gastric-issues/anal-fissures" element={<AnalFissures/>} />
        <Route exact path="/treatments/gastric-issues/gastritis" element={<Gastritis/>} />
        <Route exact path="/treatments/gastric-issues/irf" element={<Irf/>} />
        <Route exact path="/treatments/gastric-issues/gallstone" element={<Gallstone/>} />
        <Route exact path="/treatments/gastric-issues/liver-abscess" element={<LiverAbscess/>} />
        <Route exact path="/treatments/gastric-issues/fatty-liver" element={<FattyLiver/>} />
        <Route exact path="/treatments/gastric-issues/appendicitis" element={<Appendicitis/>} />
        <Route exact path="/treatments/joint-bone" element={<JointBone />} />
        <Route exact path="/treatments/joint-bone/rheumatoid-arthritis" element={<RheumatoidArthritis/>} />
        <Route exact path="/treatments/joint-bone/polyarthritis" element={<Polyarthritis/>} />
        <Route exact path="/treatments/joint-bone/gouty-arthritis" element={<GoutyArthritis/>} />
        <Route exact path="/treatments/joint-bone/osteoarthritis" element={<Osteoarthritis/>} />
        <Route exact path="/treatments/joint-bone/osteoporosis" element={<Osteoporosis/>} />
        <Route exact path="/treatments/joint-bone/reactive-arthritis" element={<ReactiveArthritis/>} />
        <Route exact path="/treatments/spinal-disease" element={<SpinalDisease />} />
        <Route exact path="/treatments/spinal-disease/cervical-spondylitis" element={<CervicalSpondylitis/>} />
        <Route exact path="/treatments/spinal-disease/lumbar-spondylosis" element={<LumbarSpondylosis/>} />
        <Route exact path="/treatments/spinal-disease/ankylosing-spondylitis" element={<AnkylosingSpondylitis/>} />
        <Route exact path="/treatments/head" element={<Head/>} />
        <Route exact path="/treatments/head/migraine" element={<Migraine/>} />
        <Route exact path="/treatments/head/classical-headaches" element={<ClassicalHeadaches/>} />
        <Route exact path="/treatments/head/brain-tumor" element={<BrainTumor/>} />
        <Route exact path="/treatments/ent" element={<Ent/>} />
        <Route exact path="/treatments/ent/allergic-rhinitis" element={<AllergicRhinitis/>} />
        <Route exact path="/treatments/ent/nasal-polyps" element={<NasalPolyps/>} />
        <Route exact path="/treatments/ent/dns" element={<Dns/>} />
        <Route exact path="/treatments/ent/tonsillitis" element={<Tonsillitis/>} />
        <Route exact path="/treatments/ent/adenoids" element={<Adenoids/>} />
        <Route exact path="/treatments/ent/ear-perforation" element={<EarPerforation/>} />
        <Route exact path="/treatments/ent/asom-csom" element={<AsomCsom/>} />
        <Route exact path="/treatments/ent/meniere-disease" element={<MeniereDisease/>} />
        <Route exact path="/treatments/hormonal-diseases" element={<HormonalDiseases/>} />
        <Route exact path="/treatments/hormonal-diseases/thyroid" element={<Thyroid/>} />
        <Route exact path="/treatments/hormonal-diseases/diabetes-mellitus" element={<DiabetesMellitus/>} />
        <Route exact path="/treatments/hormonal-diseases/growth-hormone-deficiency" element={<GrowthHormoneDeficiency/>} />
        <Route exact path="/treatments/hormonal-diseases/hypothyroidism" element={<Hypothyroidism/>} />
        <Route exact path="/treatments/hormonal-diseases/hyperthyroidism" element={<Hyperthyroidism/>} />
        <Route exact path="/treatments/pediatric" element={<Pediatric/>} />
        <Route exact path="/treatments/pediatric/recurrent-cough-cold" element={<RecurrentCough/>} />
        <Route exact path="/treatments/pediatric/behaviour-disorder" element={<BehaviourDisorder/>} />
        <Route exact path="/treatments/pediatric/attention-deficit" element={<HyperactivityDisorder/>} />
        <Route exact path="/treatments/pediatric/autism-spectrum-disorder" element={<AutismSpectrumDisorder/>} />
        <Route exact path="/treatments/pediatric/developmental-disorder" element={<DevelopmentalDisorder/>} />
        <Route exact path="/treatments/pediatric/gastric-trouble" element={<GastricTrouble/>} />
        <Route exact path="/treatments/pediatric/recurrent-pneumonia" element={<RecurrentPneumonia/>} />
        <Route exact path="/treatments/gastric-issues" element={<GastricIssues/>} />
        <Route exact path="/treatments/psychiatric" element={<Psychiatric/>} />
        <Route exact path="/treatments/psychiatric/mood-disorders" element={<MoodDisorders/>} />
        <Route exact path="/treatments/psychiatric/depression" element={<Depression/>} />
        <Route exact path="/treatments/psychiatric/dementia" element={<Dementia/>} />
        <Route exact path="/treatments/psychiatric/personality-disorder" element={<PersonalityDisorder/>} />
        <Route exact path="/treatments/psychiatric/anxiety-disorder" element={<AnxietyDisorder/>} />
        <Route exact path="/treatments/psychiatric/obsessive-compulsive-disorder" element={<ObsessivecompulsiveDisorder/>} />
        <Route exact path="/treatments/psychiatric/panic-disorder" element={<PanicDisorder/>} />
        <Route exact path="/treatments/psychiatric/phobia" element={<Phobia/>} />
        <Route exact path="/treatments/skin" element={<Skin/>} />
        <Route exact path="/treatments/skin/molluscum-contagiosum" element={<MolluscumContagiosum/>} />
        <Route exact path="/treatments/skin/lichen-planus" element={<LichenPlanus/>} />
        <Route exact path="/treatments/skin/hair-fall" element={<HairFall/>} />
        <Route exact path="/treatments/skin/psoriasis" element={<Psoriasis/>} />
        <Route exact path="/treatments/skin/vitiligo" element={<Vitiligo/>} />
        <Route exact path="/treatments/skin/nails-disorder" element={<NailsDisorder/>} />
        <Route exact path="/treatments/skin/chloasma-melasma" element={<Chloasma/>} />
        <Route exact path="/treatments/skin/contact-dermatitis" element={<ContactDermatitis/>} />
        <Route exact path="/treatments/skin/urticaria" element={<Urticaria/>} />
        <Route exact path="/treatments/skin/keloid" element={<Keloid/>} />
        <Route exact path="/treatments/skin/acne" element={<Acne/>} />
        <Route exact path="/treatments/skin/alopecia" element={<Alopecia/>} />
        <Route exact path="/treatments/male-genital" element={<MaleGenital/>} />
        <Route exact path="/treatments/male-genital/varicocele" element={<Varicocele/>} />
        <Route exact path="/treatments/male-genital/impotence" element={<Impotence/>} />
        <Route exact path="/treatments/male-genital/infertility-male" element={<InfertilityMale/>} />
        <Route exact path="/treatments/male-genital/genital-cyst" element={<GenitalCyst/>} />
        <Route exact path="/treatments/male-genital/epididymitis" element={<Epididymitis/>} />
        <Route exact path="/treatments/male-genital/mild-hydrocele" element={<MildHydrocele/>} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/contact-us/feedback" element={<Feedback />} />
        <Route exact path="/contact-us/appointment" element={<BookAppointment />} />
        <Route exact path="/contact-us/queries" element={<Queries />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blogs/casestudies" element={<CaseStudies />} />
        <Route exact path="/blogs/videocasestudies" element={<VideoCaseStudies />} />
        <Route exact path="/forpatient" element={<ForPatient />} />
        {/* Urology Diet section */}
        <Route exact path="/treatments/urology/kidney-stone/diet-kidney-stone" element={<KidneyDiet />} />
        <Route exact path="/treatments/urology/urethral-stricture/urethral-stricture-diet" element={<UrethralStrictureDiet />} />
        <Route exact path="/treatments/urology/bph-prostate/bph-diet" element={<BphDiet />} />
        <Route exact path="/treatments/urology/bladder-polyps/bladder-polyps-diet" element={<BladderPolypsDiet />} />
        <Route exact path="/treatments/urology/overactive-bladder/overactive-bladder-diet" element={<OverActiveBladderDiet />} />
        <Route exact path="/treatments/urology/uti/uti-diet" element={<UtiDiet />} />
        <Route exact path="/treatments/urology/crf-management/crf-management-diet" element={<CrfManagementDiet />} />
        <Route exact path="/treatments/urology/chronic-cystitis/chronic-cystitis-diet" element={<ChronicCystitisDiet />} />
        <Route exact path="/treatments/urology/acute-renal-failure/acute-renal-failure-diet" element={<AcuteRenalFailureDiet />} />
        {/* Female Troubles Diet section */}
        <Route exact path="/treatments/female-troubles/infertility/infertility-diet" element={<InfertilityDiet />} />
        <Route exact path="/treatments/female-troubles/pcod/pcod-diet" element={<PcodDiet />} />
        <Route exact path="/treatments/female-troubles/uterine-fibroid/uterine-fibroid-diet" element={<UterineFibroidDiet />} />
        <Route exact path="/treatments/female-troubles/menstural-disorder/menstural-disorder-diet" element={<MensturalDisorderDiet />} />
        <Route exact path="/treatments/female-troubles/dysfunctional-uterine-bleeding/dysfunctional-uterine-bleeding-diet" element={<DysfunctionalUterineBleedingDiet />} />
        <Route exact path="/treatments/female-troubles/endometriosis/endometriosis-diet" element={<EndometriosisDiet />} />
        <Route exact path="/treatments/female-troubles/ovarian-cyst/ovarian-cyst-diet" element={<OvarianCystDiet />} />
        <Route exact path="/treatments/female-troubles/leukorrhea/leukorrhea-diet" element={<LeukorrheaDiet />} />
        <Route exact path="/treatments/female-troubles/polyps/polyps-diet" element={<PolypsDiet />} />
        <Route exact path="/treatments/female-troubles/fibroadenoma/fibroadenoma-diet" element={<FibroadenomaDiet />} />
        <Route exact path="/treatments/female-troubles/hormonal-imbalance/hormonal-imbalance-diet" element={<HormonalImbalanceDiet />} />
        <Route exact path="/treatments/female-troubles/menopausal-syndrome/menopausal-syndrome-diet" element={<MenopausalSyndromeDiet />} />
        {/* Gastric Diet section */}
        <Route exact path="/treatments/gastric-issues/constipation/constipation-diet" element={<ConstipationDiet />} />
        <Route exact path="/treatments/gastric-issues/hemorrhoids/hemorrhoids-diet" element={<HemorrhoidsDiet />} />
        <Route exact path="/treatments/gastric-issues/fistula/fistula-diet" element={<FistulaDiet />} />
        <Route exact path="/treatments/gastric-issues/recurrent-aphthous/recurrent-aphthous-diet" element={<RecurrentAphthousDiet />} />
        <Route exact path="/treatments/gastric-issues/anal-fissures/anal-fissures-diet" element={<AnalFissuresDiet />} />
        <Route exact path="/treatments/gastric-issues/gastritis/gastritis-diet" element={<GastritisDiet />} />
        <Route exact path="/treatments/gastric-issues/irf/irf-diet" element={<IrfDiet />} />
        <Route exact path="/treatments/gastric-issues/gallstone/gallstone-diet" element={<GallstoneDiet />} />
        <Route exact path="/treatments/gastric-issues/liver-abscess/liver-abscess-diet" element={<LiverAbscessDiet />} />
        <Route exact path="/treatments/gastric-issues/fatty-liver/fatty-liver-diet" element={<FattyLiverDiet />} />
        <Route exact path="/treatments/gastric-issues/appendicitis/appendicitis-diet" element={<AppendicitisDiet />} />
        {/* Skin Diet Section */}
        <Route exact path="/treatments/skin/molluscum-contagiosum/molluscum-contagiosum-diet" element={<MolluscumContagiosumDiet />} />
        <Route exact path="/treatments/skin/lichen-planus/lichen-planus-diet" element={<LichenPlanusDiet />} />
        <Route exact path="/treatments/skin/hair-fall/hair-fall-diet" element={<HairFallDiet />} />
        <Route exact path="/treatments/skin/acne/acne-diet" element={<AcneDiet />} />
        <Route exact path="/treatments/skin/alopecia/alopecia-diet" element={<AlopeciaDiet />} />
        <Route exact path="/treatments/skin/psoriasis/psoriasis-diet" element={<PsoriasisDiet />} />
        <Route exact path="/treatments/skin/vitiligo/vitiligo-diet" element={<VitiligoDiet />} />
        <Route exact path="/treatments/skin/nails-disorder/nails-disorder-diet" element={<NailsDisorderDiet />} />
        <Route exact path="/treatments/skin/chloasma-melasma/chloasma-diet" element={<ChloasmaDiet />} />
        <Route exact path="/treatments/skin/contact-dermatitis/contact-dermatitis-diet" element={<ContactDermatitisDiet />} />
        <Route exact path="/treatments/skin/urticaria/urticaria-diet" element={<UrticariaDiet />} />
        <Route exact path="/treatments/skin/keloid/keloid-diet" element={<KeloidDiet />} />
        {/* Hormonalal Disorder Diet Section */}
        <Route exact path="/treatments/hormonal-diseases/thyroid/thyroid-diet" element={<Thyroiddiet />} />
        <Route exact path="/treatments/hormonal-diseases/diabetes-mellitus/diabetes-mellitus-diet" element={<DiabetesMellitusDiet />} />
        <Route exact path="/treatments/hormonal-diseases/growth-hormone-deficiency/growth-hormone-deficiency-diet" element={<GrowthHormoneDeficiencyDiet />} />
        <Route exact path="/treatments/hormonal-diseases/hyperthyroidism/hyperthyroidism-diet" element={<HyperthyroidismDiet />} />
        <Route exact path="/treatments/hormonal-diseases/hypothyroidism/hypothyroidism-diet" element={<HypothyroidismDiet />} />
        {/* Head Diet Section */}
        <Route exact path="/treatments/head/migraine/migraine-diet" element={<MigraineDiet />} />
        <Route exact path="/treatments/head/classical-headaches/headaches-diet" element={<HeadachesDiet />} />
        <Route exact path="/treatments/head/brain-tumor/brain-tumor-diet" element={<BrainTumorDiet />} />
        {/* ENT Diet Section */} 
        <Route exact path="/treatments/ent/allergic-rhinitis/allergic-rhinitis-diet" element={<AllergicRhinitisDiet />} />
        <Route exact path="/treatments/ent/nasal-polyps/nasal-polyps-diet" element={<NasalPolypsDiet />} />
        <Route exact path="/treatments/ent/dns/dns-diet" element={<DnsDiet />} />
        <Route exact path="/treatments/ent/tonsillitis/tonsillitis-diet" element={<TonsillitisDiet />} />
        <Route exact path="/treatments/ent/adenoids/adenoids-diet" element={<AdenoidsDiet />} />
        <Route exact path="/treatments/ent/ear-perforation/ear-perforation-diet" element={<EarPerforationDiet />} />
        <Route exact path="/treatments/ent/asom-csom/asom-csom-diet" element={<AsomCsomDiet />} />
        <Route exact path="/treatments/ent/meniere-disease/meniere-disease-diet" element={<MeniereDiseaseDiet />} />
        {/* male genitle Diet Section */} 
        <Route exact path="/treatments/male-genital/varicocele/varicocele-diet" element={<VaricoceleDiet />} />
        <Route exact path="/treatments/male-genital/impotence/impotence-diet" element={<ImpotenceDiet />} />
        <Route exact path="/treatments/male-genital/infertility-male/infertility-male-diet" element={<InfertilityMaleDiet />} />
        <Route exact path="/treatments/male-genital/genital-cyst/genital-cyst-diet" element={<GenitalCystDiet />} />
        <Route exact path="/treatments/male-genital/epididymitis/epididymitis-diet" element={<EpididymitisDiet />} />
        <Route exact path="/treatments/male-genital/mild-hydrocele/mild-hydrocele-diet" element={<MildHydroceleDiet />} />
        {/* Psychiatric Diet Section */}
        <Route exact path="/treatments/psychiatric/mood-disorders/mood-disorders-diet" element={<MoodDisordersDiet />} />
        <Route exact path="/treatments/psychiatric/depression/depression-diet" element={<DepressionDiet />} />
        <Route exact path="/treatments/psychiatric/dementia/dementia-diet" element={<DementiaDiet />} />
        <Route exact path="/treatments/psychiatric/personality-disorder/personality-disorder-diet" element={<PersonalityDisorderDiet />} />
        <Route exact path="/treatments/psychiatric/anxiety-disorder/anxiety-disorder-diet" element={<AnxietyDisorderDiet />} />
        <Route exact path="/treatments/psychiatric/obsessive-compulsive-disorder/ocd-diet" element={<OcdDiet />} />
        <Route exact path="/treatments/psychiatric/panic-disorder/panic-disorder-diet" element={<PanicDisorderDiet />} />
        <Route exact path="/treatments/psychiatric/phobia/phobia-diet" element={<PhobiaDiet />} />
        {/* child Diet Section */}
        <Route exact path="/treatments/pediatric/recurrent-cough-cold/recurrent-cough-cold-diet" element={<RecurrentCoughColdDiet />} />
        <Route exact path="/treatments/pediatric/behaviour-disorder/behaviour-disorder-diet" element={<BehaviourDisorderDiet />} />
        <Route exact path="/treatments/pediatric/attention-deficit/attention-deficit-diet" element={<AttentionDeficitDiet />} />
        <Route exact path="/treatments/pediatric/autism-spectrum-disorder/autism-spectrum-disorder-diet" element={<AutismSpectrumDisorderDiet />} />
        <Route exact path="/treatments/pediatric/developmental-disorder/developmental-disorder-diet" element={<DevelopmentalDisorderDiet />} />
        <Route exact path="/treatments/pediatric/gastric-trouble/gastric-trouble-diet" element={<GastricTroubleDiet />} />
        <Route exact path="/treatments/pediatric/recurrent-pneumonia/recurrent-pneumonia-diet" element={<RecurrentPneumoniaDiet />} />
        {/* spinal Diet Section */}
        <Route exact path="/treatments/spinal-disease/cervical-spondylitis/cervical-spondylitis-diet" element={<CervicalSpondylitisDiet />} />
        <Route exact path="/treatments/spinal-disease/lumbar-spondylosis/lumbar-spondylosis-diet" element={<LumbarSpondylosisDiet />} />
        <Route exact path="/treatments/spinal-disease/ankylosing-spondylitis/ankylosing-spondylitis-diet" element={<AnkylosingSpondylitisDiet />} />
        {/* joint & bone Diet Section */}
        <Route exact path="/treatments/joint-bone/rheumatoid-arthritis/rheumatoid-arthritis-diet" element={<RheumatoidArthritisDiet />} />
        <Route exact path="/treatments/joint-bone/gouty-arthritis/gouty-arthritis-diet" element={<GoutyArthritisDiet />} />
        <Route exact path="/treatments/joint-bone/osteoarthritis/osteoarthritis-diet" element={<OsteoarthritisDiet />} />
        <Route exact path="/treatments/joint-bone/polyarthritis/polyarthritis-diet" element={<PolyarthritisDiet />} />
        <Route exact path="/treatments/joint-bone/osteoporosis/osteoporosis-diet" element={<OsteoporosisDiet />} />
        <Route exact path="/treatments/joint-bone/reactive-arthritis/reactive-arthritis-diet" element={<ReactiveArthritisDiet />} />
        {/* blog section */}
        <Route exact path="/blogs/homeopathy" element={<Homeopathy />} />
        <Route exact path="/blogs/founder" element={<Founder />} />
        <Route exact path="/blogs/homeopathy-info" element={<HomeopathyInfo />} />
        <Route exact path="/blogs/mental-health" element={<MentalHealth />} />
        <Route exact path="/blogs/modern-homeopathy" element={<ModernHomeopathy />} />
        <Route exact path="/blogs/homeopathy-vs-allopathy" element={<HomeopathyVsAllopathy />} />
        <Route exact path="/blogs/boost-immunity" element={<BoostImmunity />} />
        <Route exact path="/blogs/digestive" element={<Digestive />} />
        <Route exact path="/blogs/pediatric-care" element={<PediatricCare />} />
        <Route exact path="/blogs/seasonal-allergies" element={<SeasonalAllergies />} />
        <Route exact path="/blogs/joint-pain" element={<JointPain />} />
        <Route exact path="/blogs/myth" element={<Myth />} />
        {/* CASE STUDIES SECTION */}
        <Route exact path="/case-studies/case-kidney" element={<CaseKidney />} />
        <Route exact path="/case-studies/case-urethral" element={<CaseUrethral />} />
        <Route exact path="/case-studies/case-alopecia" element={<CaseAlopecia />} />
        <Route exact path="/case-studies/case-arthritis" element={<CaseArthritis />} />
        <Route exact path="/case-studies/case-skin" element={<CaseSkin />} />
        <Route exact path="/case-studies/case-hormones" element={<CaseHormones />} />
        <Route exact path="/case-studies/case-migraine" element={<CaseMigraine />} />
        <Route exact path="/case-studies/case-fertility" element={<CaseFertility />} />
        <Route exact path="/case-studies/case-ent" element={<CaseEnt />} />
        <Route exact path="/case-studies/case-old-age" element={<CaseOldAge />} />
        <Route exact path="/case-studies/case-female" element={<CaseFemale />} />
        <Route exact path="/case-studies/case-male" element={<CaseMale />} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
