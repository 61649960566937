import React, { useState, useEffect, useRef } from 'react';
import '../css/Navbar.css';
import { FaFacebookF, FaInstagram, FaYoutube, FaGoogle, FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
 
const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);
 
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
 
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  };
 
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);
 
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={require('../images/logon.png')} alt="Niramaya Logo" className="logo" />
          <span className="company-name">Niramaya Homeopathy</span>
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
       
        {/* Nav Links */}
        <ul ref={menuRef} className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={() => setIsMobileMenuOpen(false)}>Home</Link></li>
          <li><Link to="/about" onClick={() => setIsMobileMenuOpen(false)}>About Us</Link></li>
          <li className="dropdown">
            <Link to="/treatments" onClick={() => setIsMobileMenuOpen(true)}>Treatments <FaChevronDown size={14} style={{ marginLeft: "5px" }} /></Link>
            <ul className="dropdown-menu">
              <li><Link to="/treatments/urology" onClick={() => setIsMobileMenuOpen(false)}>Urology (Kidney & Bladder)</Link></li>
              <li><Link to="/treatments/female-troubles" onClick={() => setIsMobileMenuOpen(false)}>Female Troubles</Link></li>
              <li><Link to="/treatments/joint-bone" onClick={() => setIsMobileMenuOpen(false)}>Joint & Bone</Link></li>
              <li><Link to="/treatments/spinal-disease" onClick={() => setIsMobileMenuOpen(false)}>Spinal Disease</Link></li>
              <li><Link to="/treatments/head" onClick={() => setIsMobileMenuOpen(false)}>Head</Link></li>
              <li><Link to="/treatments/ent" onClick={() => setIsMobileMenuOpen(false)}>ENTs</Link></li>
              <li><Link to="/treatments/hormonal-diseases" onClick={() => setIsMobileMenuOpen(false)}>Hormonal Diseases</Link></li>
              <li><Link to="/treatments/pediatric" onClick={() => setIsMobileMenuOpen(false)}>Pediatric</Link></li>
              <li><Link to="/treatments/gastric-issues" onClick={() => setIsMobileMenuOpen(false)}>Gastric Issues</Link></li>
              <li><Link to="/treatments/psychiatric" onClick={() => setIsMobileMenuOpen(false)}>Psychiatric Disorder</Link></li>
              <li><Link to="/treatments/skin" onClick={() => setIsMobileMenuOpen(false)}>Skin</Link></li>
              <li className="dropdown">
                <Link to="/treatments/male-genital" onClick={(e) => e.preventDefault()}>Male Genital Issue</Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to="/contact-us" onClick={() => setIsMobileMenuOpen(true)}>Contact Us <FaChevronDown size={14} style={{ marginLeft: "5px" }} /></Link>
            <ul className="dropdown-menu">
              <li><Link to="/contact-us/feedback" onClick={() => setIsMobileMenuOpen(false)}>Feedback</Link></li>
              <li><Link to="/contact-us/appointment" onClick={() => setIsMobileMenuOpen(false)}>Book Appointment</Link></li>
              <li><Link to="/contact-us/queries" onClick={() => setIsMobileMenuOpen(false)}>Queries</Link></li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to="/blogs" onClick={() => setIsMobileMenuOpen(true)}>Blogs <FaChevronDown size={14} style={{ marginLeft: "5px" }} /></Link>
            <ul className="dropdown-menu">
              <li><Link to="/blogs/casestudies" onClick={() => setIsMobileMenuOpen(false)}>Case Studies</Link></li>
              <li><Link to="/blogs/videocasestudies" onClick={() => setIsMobileMenuOpen(false)}>Video Case Studies</Link></li>
            </ul>
          </li>
          <li><Link to="/forpatient" onClick={() => setIsMobileMenuOpen(false)}>For Patient</Link></li>
        </ul>
 
        {/* Social Media Icons */}
        <div className={`social-icons ${isMobileMenuOpen ? 'active' : ''}`}>
          <a href="https://www.facebook.com/doctoratulsingh" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          <a href="https://www.instagram.com/niramaya_homoeopathy01/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.youtube.com/@dratulkumarsingh" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          <a href="https://www.google.com/search?client=ms-android-oppo-rev1&sca_esv=83baeecfea64ff8d&hl=en-US&cs=1&sxsrf=ADLYWILDusQTCSytw3uDeiN1HuRdoR_8-A:1728564335119&q=Dr+Atul+kumar+singh+(BHMS,+MD,+PG+London)+Homeopath+Gorakhpur&ludocid=12633890231795941404&ibp=gwp;0,7&lsa=CAAQARgBKAY&ei=b8wHZ-vwBuaPseMP6_6V-QQ&gad_source=1&gclid=Cj0KCAjw9p24BhB_Ei0A8ID5BtJW97UAPaeYuFr1UI1mS5GvWBSUCHCyjYbD_rZbMLostdmtHLudD8waAtDDEALw_wcB#lpg=cid:CgIgAQ%3D%3D" target="_blank" rel="noopener noreferrer"><FaGoogle /></a>
        </div>
      </div>
    </nav>
  );
};
 
export default Navbar;