import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh7.jpg')}alt="Urology Hero" />
        <h1>Ovarian Cyst Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Ovarian Cyst</h2>
          <img src={require('../images/female7.jpg')}alt="Urology Hero" />
         <h3>Basic Homoeopathic Approach</h3>
         <p>
            Homoeopathy treats ovarian cysts by considering the person’s overall constitution, including their physical, mental, and emotional health. Rather than focusing solely on the cyst itself, the goal is to address the underlying imbalances that may be contributing to cyst formation. Homoeopathic remedies are selected based on the individual’s specific symptoms and characteristics, aiming to restore hormonal balance and support the body’s natural healing processes.
         </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Hormonal Imbalances:</h5>Imbalances in oestrogen and progesterone can lead to the formation of functional ovarian cysts, which often resolve on their own.            </li>
            <li><h5>Polycystic Ovary Syndrome (PCOS):</h5>A condition characterised by multiple small cysts on the ovaries, often linked to insulin resistance and an imbalance in reproductive hormones.            </li>
            <li><h5>Endometriosis:</h5>Endometrial tissue can grow on the ovaries and form endometriomas (chocolate cysts), which are often painful.            </li>
            <li><h5>Pregnancy:</h5>Sometimes, ovarian cysts can form in early pregnancy, such as corpus luteum cysts that usually resolve as pregnancy progresses.            </li>
            <li><h5>Pelvic Infections:</h5>Severe infections can spread to the ovaries and fallopian tubes, leading to cyst formation. </li>
          </ul>
          <h3>Types of Ovarian Cysts</h3>
          <ul>
            <li><h5>Functional Cysts:</h5>Includes follicular cysts and corpus luteum cysts, which are generally benign and often resolve on their own.</li>
            <li><h5>Dermoid Cysts:</h5>Also known as teratomas, these cysts can contain various tissues like hair, skin, and teeth.            </li>
            <li><h5>Cystadenomas:</h5>Develop on the surface of the ovary and can be filled with watery or mucous material.            </li>
            <li><h5>Endometriomas:</h5>Cysts caused by endometriosis, containing dark, thick blood.</li>
          </ul>
          <h3>Investigations:</h3>
          <ul>
            <li><h5>Ultrasound:</h5>A non-invasive imaging test to examine the size, location, and type of the cyst.            </li>
            <li><h5>Hormone Tests:</h5>Blood tests to measure levels of FSH, LH, estradiol, and testosterone, which can help assess hormonal imbalances.            </li>
            <li><h5>CA-125 Blood Test:</h5>Used primarily for post-menopausal women to assess the risk of ovarian cancer, especially if a cyst is complex or persistent.            </li>
            <li><h5>Laparoscopy:</h5>A minimally invasive procedure to visualise the ovaries directly and possibly remove the cyst.            </li>
          </ul>
          <h3>Complications and Risk Factors:</h3>
          <ul>
            <li><h5>Ovarian Torsion:</h5>Larger cysts can cause the ovary to twist, cutting off its blood supply and causing severe pain.            </li>
            <li><h5>Rupture:</h5>Some cysts can burst, leading to sharp pain and internal bleeding.            </li>
            <li><h5>Infertility:</h5>Conditions like PCOS and endometriosis-related cysts can affect fertility if left untreated.            </li>
            <li><h5>Cancer:</h5>While most ovarian cysts are benign, there is a small risk of cancer, especially in post-menopausal women.            </li>
          </ul>
          <h3>Traditional Basic Treatment:          </h3>
          <ul>
            <li><h5>Hormonal Therapy:</h5>Birth control pills or other hormonal medications can help regulate the menstrual cycle and reduce the likelihood of new cyst formation.            </li>
            <li><h5>Surgery:</h5>If a cyst is large, persistent, or symptomatic, surgical removal (cystectomy) may be necessary.            </li>
            <li><h5>Pain Management:</h5>NSAIDs and prescription pain relievers may be used to manage symptoms associated with cysts.            </li>
            <li><h5>Lifestyle Changes:</h5>Adopting a balanced diet, regular exercise, and stress-reduction techniques can help support overall hormonal health.            </li>
          </ul>
          <h3>Homoeopathic Treatment:</h3>
          <ul>
            <li><h5>Apis Mellifica:</h5>Suitable for cysts causing sharp, stinging pains and swelling, especially when symptoms worsen with heat and improve with cold applications.            </li>
            <li><h5>Bellis Perennis:</h5>For ovarian cysts accompanied by soreness and tenderness in the pelvic area, especially post-surgery or injury.            </li>
            <li><h5>Lachesis:</h5>Recommended for left-sided ovarian cysts with burning pain, symptoms that worsen at night, and relief from discharges.            </li>
            <li><h5>Palladium:</h5>Useful for ovarian cysts with a sensation of heaviness or dragging, especially on the right side.</li>
            <li><h5>Thuja Occidentalis:</h5>Often used for cysts in women with a history of hormonal disturbances and those prone to warts or other skin issues.            </li>
          </ul>
          <h3>Conclusion</h3>
          <p>In our experience, homoeopathy offers a gentle, supportive approach to treating ovarian cysts by addressing the whole person. Many patients report reduced pain, improved hormonal balance, and a better sense of well-being with homoeopathic treatment. While homoeopathy may not replace conventional treatments, it serves as an effective complementary approach, particularly for those seeking a natural and less invasive option. Homoeopathic remedies can support the body’s natural healing process, promoting long-term health and preventing recurrence.          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Ovarian Cyst Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Ovarian Cyst conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/ovarian-cyst/ovarian-cyst-diet">Diet for relief of Ovarian Cyst disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Ovarian Cyst              </a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Ovarian Cyst Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Ovarian Cyst Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/wqjud6HEjk8?si=6xlBC8ZH1_B1a6cF"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};

export default KidneyPage;
