import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/eh4.jpg')}alt="Urology Hero" />
        <h1>Tonsillitis Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Tonsillitis</h2>
          <img src={require('../images/ent4.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy provides a holistic and individualized approach to treating tonsillitis. The focus is on alleviating symptoms, reducing inflammation, and strengthening the body’s immune system to prevent recurrence. Remedies are selected based on the principle of “like cures like,” which aims to stimulate the body’s natural healing mechanisms. This personalized approach considers the patient’s overall health, specific symptoms, and emotional well-being, ensuring long-term relief and enhanced quality of life.
          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Viral Infections:</h5>Most cases of tonsillitis are caused by common viruses such as adenovirus, influenza virus, and Epstein-Barr virus.</li>
            <li><h5>Bacterial Infections:</h5> Streptococcus pyogenes (group A streptococcus) is the most common bacterial cause of tonsillitis.</li>
            <li><h5>Other Pathogens: </h5>Less common causes include fungi and parasites.</li>
            <li><h5>Weakened Immune System:</h5>Individuals with compromised immune systems are more susceptible to infections that can cause tonsillitis.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Detailed assessment of symptoms and previous episodes of tonsillitis. Physical examination includes checking for swollen tonsils, redness, white or yellow patches, and swollen lymph nodes.</li>
            <li><h5>Throat Swab: </h5>A sample taken from the back of the throat to test for bacterial infections, particularly group A streptococcus.</li>
            <li><h5>Blood Tests:</h5>Complete blood count (CBC) and specific tests to identify viral infections such as mononucleosis.</li>
            <li><h5>Rapid Strep Test:</h5>A quick test to detect the presence of streptococcus bacteria.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Recurrent Tonsillitis:</h5> Frequent episodes of tonsillitis can lead to chronic inflammation and enlargement of the tonsils.</li>
            <li><h5>Peritonsillar Abscess:</h5>A painful collection of pus near the tonsils that can cause severe throat pain and difficulty swallowing.</li>
            <li><h5>Obstructive Sleep Apnea:</h5>Enlarged tonsils can obstruct the airway, leading to breathing difficulties during sleep.</li>
            <li><h5>Rheumatic Fever:</h5>Untreated streptococcal tonsillitis can lead to rheumatic fever, affecting the heart, joints, and nervous system.</li>
            <li><h5>Post-Streptococcal Glomerulonephritis: </h5>A kidney disorder that can develop after a streptococcal infection.</li>
            <li><h5>Risk Factors: </h5>Close contact with individuals who have respiratory infections, age (children and teenagers are more susceptible), and exposure to environmental pollutants and allergens.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Antibiotics for bacterial infections, pain relievers, and anti-inflammatory medications to reduce pain and swelling.</li>
            <li><h5>Throat Lozenges and Sprays: </h5>To soothe throat pain and irritation.</li>
            <li><h5>Warm Saltwater Gargles:</h5>To reduce throat discomfort and inflammation.</li>
            <li><h5>Hydration and Rest:</h5>Drinking plenty of fluids and getting adequate rest to support the immune system.</li>
            <li><h5>Surgery:</h5>Tonsillectomy to remove the tonsils in cases of chronic or recurrent tonsillitis.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li><h5>Belladonna: </h5>For sudden onset of tonsillitis with high fever, red and swollen tonsils, and intense throat pain.</li>
            <li><h5>Hepar Sulph:</h5>For tonsillitis with pus formation, sharp splinter-like pain, and sensitivity to cold.</li>
            <li><h5>Mercurius Solubilis:</h5>For swollen, ulcerated tonsils with bad breath, excessive salivation, and night sweats.</li>
            <li><h5>Phytolacca: </h5>For tonsillitis with dark red, swollen tonsils, pain radiating to the ears, and difficulty swallowing.</li> 
            <li><h5>Lachesis:</h5>For left-sided tonsillitis with dark red or purple tonsils, worse from swallowing liquids, and relief from cold drinks.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homeopathic treatment for tonsillitis focuses on personalized care and holistic management. By addressing both the symptoms and underlying causes, homeopathy supports the body’s natural healing processes and homeopathic medicine for tonsils and improves overall well-being. Many patients experience significant relief from symptoms, reduced frequency of tonsillitis episodes, and enhanced quality of life with consistent homeopathic care. Our experience demonstrates that with appropriate remedy selection and patient compliance, homeopathy can effectively complement conventional treatments. This approach not only alleviates symptoms but also promotes long-term health benefits, emphasizing individualized treatment and lifestyle adjustments. Homeopathy offers a gentle and non-invasive alternative, emphasizing holistic care and restoring balance to promote optimal health and well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Tonsillitis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Tonsillitis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/ent/tonsillitis/tonsillitis-diet">Diet for Tonsillitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Tonsillitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Tonsillitis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Tonsillitis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/JX4n46pJnvA?si=QLRu8t-sxpnyMGff"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
