import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Treatments.css';

const Treatments = () => {
  const treatments = [
    { title: 'Urology(Kidney & Bladder)', description: 'Holistic treatment for urinary system issues like kidney stones, bladder infections', icon: 'treatment1.jpg', path: '/treatments/urology' },
    { title: 'Female Troubles', description: 'Explore homeopathic treatment options for Duchenne Muscular Dystrophy (DMD) to manage symptoms and improve quality of life.', icon: 'treatment2.jpg', path: '/treatments/female-troubles' },
    { title: 'Gastric Issues', description: 'Treat digestive issues like IBS, acidity, and constipation with homeopathy.', icon: 'treatment4.jpg', path: '/treatments/gastric-issues' },
    { title: 'Skin Problem', description: 'Homeopathic treatment for fibroids: Gentle, natural approach, avoiding surgery\'s common risks. Supports women on the path to parenthood.', icon: 'treatment6.jpg', path: '/treatments/skin' },
    { title: 'Hormonal Disorder', description: 'Balance your body’s hormonal imbalances with natural homeopathic treatments.', icon: 'treatment5.jpg', path: '/treatments/hormonal-diseases' },
    { title: 'Migraines & Headache', description: 'Managing Congenital Hypothyroidism with Homeopathic Treatment: A holistic approach to care.', icon: 'treatment3.jpg', path: '/treatments/head' },
    { title: 'ENTs', description: 'Effective homeopathic treatment for head, throat, nose, and ear-related issues.', icon: 'treatment7.jpg', path: '/treatments/ent' },
    { title: 'Male Genital Issues', description: 'Consult online for homeopathic treatment to manage this rare genetic disorder.', icon: 'treatment8.jpg', path: '/treatments/male-genital' },
    { title: 'Psychiatrist', description: 'Gentle homeopathic solutions for children’s health, including immunity, digestion, and more.', icon: 'treatment9.jpg', path: '/treatments/psychiatric' },
    { title: 'Pediatric', description: 'Comprehensive care for skin conditions like eczema, psoriasis, and acne.', icon: 'treatment10.jpg', path: '/treatments/pediatric' },
    { title: 'Spinal Disease', description: 'Natural remedies for asthma, bronchitis, and other respiratory disorders.', icon: 'treatment11.jpg', path: '/treatments/spinal-disease' },
    { title: 'Joint & Bone', description: ' Treating joint, bone, and spine-related conditions with homeopathic solutions.', icon: 'treatment12.jpg', path: '/treatments/joint-bone' }
  ];
 
  return (
    <div className="treatment-page">
      <div className="treatment-hero-section">
      <div className='treatment-new'>
      <h1>Homeopathic Treatment</h1>
      <h2>Homeopathic is Supposed to Work Miracale.</h2>
      </div>
        {/* <img src={require('../images/t1.jpg')} alt="Treatment Hero" className="treatment-hero-img" /> */}
      </div>
      <div className="treatments-cards-container">
        {treatments.map((treatment, index) => (
          <Link to={treatment.path} key={index} className="treatment-card-link"> 
            <div className="treatments-cards">
              <img src={require(`../images/${treatment.icon}`)} alt={treatment.title} className="treatments-icons" />
              <h3>{treatment.title}</h3>
              <p>{treatment.description}</p>
              <div className="books-appointment-btn" href="/forpatient">
                <i className="fa fa-calendar"></i> Book Appointment
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="connect-section">
        <div className="connect-banner">
          <h2>Connect With Us</h2>
          <a href="https://wa.me/7777085655" className="chat-now-btn">Chat Now</a>
          <p>Get personalized healthcare from our specialist homeopathy doctors with online consultations now available.</p>
        </div>

        {/* Centers and Contact Us Section */}
        <div className="centers-contact-section">
          <div className="center-description">
            <img src={require('../images/logon.png')} alt="Homoeocare" className="homoeocare-logo" />
            <p>
              HomoeoCARE is not just an organization, but a compassionate community that embodies the values of empathy,
              sensitivity, and care. As our slogan states, "We care for you," and we are committed to supporting those in need
              with our innovative homeopathic solutions. HomoeoCARE has gained a reputation as the best homeopathic clinic
              for delivering amazing results where conventional medicine has fallen short. Visit our cases section to witness
              the power of homeopathy for yourself.
            </p>
          </div>

          <div className="center-contact">
            <div className="contact-us">
              <h3>CONTACT US</h3>
              <p>0230 B Civil Lines 2 Bilandpur near DIG Bunglow<br />
              Gorakhpur, Uttar Pradesh, India, 273001</p>
              <p>Call: <a href="tel:+919236185711">+919236185711</a></p>
              <p>Email: <a href="mailto:niramayaforyou@gmail.com">niramayaforyou@gmail.com</a></p>
              <p>Mon to Sat: 10:00 AM to 8:00 PM</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treatments;
