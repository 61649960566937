import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/meh8.jpg')}alt="Urology Hero" />
        <h1>Phobia Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Phobia</h2>
          <img src={require('../images/mental8.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <ul>
            <li>Mood disorders can arise from a combination of genetic predisposition, biochemical imbalances (such as neurotransmitter dysregulation), hormonal changes, chronic stress, trauma, and environmental factors like lifestyle and relationships.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Homeopathy approaches mood disorders by viewing them as reflections of underlying emotional and mental imbalances. It focuses on treating the whole person rather than just the symptoms, aiming to restore harmony in mental and emotional states.            </li>
            <li>Remedies are carefully selected based on a detailed assessment of the patient’s emotional symptoms, personality traits, and response to stressors.            </li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Homeopathic diagnosis involves a comprehensive evaluation of the patient’s mental and emotional state, including their response to stress, life events, and personal history.            </li>
            <li>Physical examinations and tests may be conducted to rule out underlying medical conditions that could contribute to mood disturbances.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications of untreated mood disorders include impaired social functioning, difficulties in personal relationships, substance abuse, and an increased risk of suicidal thoughts or behaviors.</li>
            <li>Risk factors include a family history of mood disorders, prolonged stress, traumatic experiences, and chronic health conditions affecting mental health.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments often include psychotherapy (such as cognitive-behavioral therapy and interpersonal therapy) to address thought patterns and behaviors contributing to mood disturbances.</li>
            <li>Pharmacotherapy with antidepressants or mood stabilizers may be prescribed to manage symptoms and restore neurotransmitter balance.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li>Homeopathic remedies for mood disorders are selected based on the individual’s unique emotional profile and symptom presentation.</li>
            <li>Examples include Aurum metallicum for severe depression with feelings of hopelessness and Natrum mur for grief-related sadness or emotional withdrawal.</li>
            <li>Ignatia may be used for acute emotional distress or mood swings triggered by sudden emotional events.</li>
            </ul>
          <h3>Conclusion</h3>
          <ul>
            <li>Homeopathy offers a personalized and holistic approach to treating mood disorders, focusing on restoring emotional balance and overall well-being.</li>
            <li>Effectiveness can vary depending on individual responsiveness to remedies and the skill of the homeopath in selecting appropriate treatments.</li>
            <li>Many patients report improvements in mood stability, emotional resilience, and overall quality of life with homeopathic treatment, especially when integrated with supportive therapies and lifestyle changes. </li>
          </ul>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Phobia Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Phobia conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/psychiatric/phobia/phobia-diet">Diet for Phobia</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Phobia</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Phobia</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Phobia Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/8iB49_rtskU?si=wj0lzIj5RPnIufnF"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
