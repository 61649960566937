import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh2.jpg')}alt="Urology Hero" />
        <h1>PCOD Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Pcod</h2>
          <img src={require('../images/female2.jpg')}alt="Urology Hero" />
          <p>
          PCOD (Polycystic Ovary Syndrome) affects a significant portion of the female population, with varying prevalence estimates globally:
          </p>
          <li><h5>Prevalence:</h5>PCOD is estimated to affect 5% to 10% of women of reproductive age worldwide. The prevalence can vary based on geographic location, ethnicity, and diagnostic criteria used.</li>
          <li><h5>Impact:</h5>It is one of the most common endocrine disorders in women and a leading cause of infertility due to irregular ovulation.</li>
          <h3>Causes</h3>
          <p>The exact cause of PCOD is not fully understood, but it is believed to involve a combination of genetic and environmental factors:</p>
          <ul>
            <li><h5>Hormonal Imbalance:</h5>Elevated levels of androgens (male hormones) like testosterone.</li>
            <li><h5>Insulin Resistance:</h5>Cells fail to respond normally to insulin, leading to increased insulin levels which can stimulate androgen production.</li>
            <li><h5>Genetics:</h5>Family history of PCOD can increase the risk.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Assess symptoms, risk factors, and overall health status.</li>
            <li><h5>Imaging: </h5>X-ray, CT scan, or ultrasound to visualize stones, determine their size, location, and assess potential complications.</li>
            <li><h5>Urinalysis:</h5>Examination of urine composition to identify crystals and abnormalities.</li>
            <li><h5>Blood Tests:</h5> Evaluate kidney function, mineral levels, and detect underlying conditions.</li>
          </ul>
          <h3>Signs and Symptoms:</h3>
          <p>PCOD is characterized by a variety of signs and symptoms, which can vary in severity among individuals:</p>
          <ul>
            <li><h5>Menstrual Irregularities:</h5>Irregular, infrequent, or absent menstrual periods.</li>
            <li><h5>Hyperandrogenism:</h5>Excess levels of androgens leading to symptoms such as hirsutism (excess hair growth), acne, and male-pattern baldness.</li>
            <li><h5>Polycystic Ovaries:</h5>Multiple small follicles (cysts) on the ovaries detected by ultrasound.</li>
          </ul>
          <h3>Diet and Regiment Treatment:</h3>
          <p>Managing PCOD often involves a multifaceted approach, including:</p>
          <ul>
            <li><h5>Diet:</h5> Emphasizing a balanced diet rich in whole grains, fruits, vegetables, lean proteins, and healthy fats. Avoiding processed foods, sugars, and refined carbohydrates can help manage insulin levels.</li>
            <li><h5>Exercise:</h5>Regular physical activity improves insulin sensitivity and can help regulate menstrual cycles.</li>
            <li><h5>Medications:</h5>Depending on symptoms and goals, treatment may include birth control pills to regulate menstrual cycles, anti-androgen medications to manage symptoms like hirsutism and acne, and metformin to improve insulin sensitivity.</li>
            <li><h5>Lifestyle Changes:</h5>Weight management is crucial, as even a modest weight loss can improve symptoms and hormone levels.</li>
          </ul>
          <h3>Hazards:</h3>
          <ul>
            <li><h5>Long-term Health Risks:</h5>PCOD is associated with increased risks of type 2 diabetes, high blood pressure, and endometrial cancer if not managed effectively.</li>
            <li><h5>Impact on Fertility:</h5>Irregular ovulation and hormonal imbalances can lead to infertility or difficulty conceiving.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li><h5>Approach: </h5>Homeopathy treats the individual holistically, considering their unique symptoms and overall health.</li>
            <li><h5>Medicines:</h5> Homeopathic remedies for PCOD may include Sepia, Pulsatilla, Lachesis, and others, chosen based on individual symptoms and constitution.</li>
            <li><h5>Effectiveness:</h5>While some individuals report improvement in symptoms with homeopathic treatments, scientific evidence on the efficacy of homeopathy for PCOD is limited.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>PCOD is a common hormonal disorder affecting women worldwide, with significant impacts on health and fertility. Early diagnosis, lifestyle modifications, and appropriate medical treatment are crucial for managing symptoms and reducing long-term health risks. When considering alternative treatments like homeopathy, it’s important to consult with qualified practitioners and integrate them into a comprehensive treatment plan under medical supervision.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>PCOD Treatment</h3>
            <p>We offer natural, safe, and effective treatment for PCOD conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/pcod/pcod-diet">Diet for relief of PCOD</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of PCOD</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for PCOD Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on PCOD Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/arHGSCJUYJ4?si=7DtXSwGNpmt5_nQJ"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};

export default KidneyPage;
