import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh9.jpg')}alt="Urology Hero" />
        <h1>Liver abscess</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Liver abscess</h2>
          <img src={require('../images/gastric9.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <p>
        Homoeopathy treats liver abscesses by focusing on the person’s overall health and immune response. Remedies are selected based on the individual’s symptoms and constitution, aiming to reduce inflammation, relieve pain, and support the body’s natural healing processes. While conventional treatments are often required for severe cases, homoeopathy can serve as a supportive approach to homeopathic medicine for liver disease & help manage symptoms and promote recovery.        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Bacterial Infections:</h5>Pyogenic (Bacterial) Abscess: Most commonly caused by bacteria like E. coli, Klebsiella, or Streptococcus that enter the liver through the bloodstream, bile ducts, or neighbouring organs.            </li>
            <li><h5>Amoebic Infections:</h5>Amoebic Liver Abscess: Caused by the parasite Entamoeba histolytica, which can reach the liver through the bloodstream after infecting the intestines.            </li>
            <li><h5>Injury or Trauma:</h5>Liver trauma, such as from surgery or a severe injury, can create an environment that allows for bacterial growth and abscess formation.            </li>
            <li><h5>Gallbladder or Biliary Tract Infections:</h5>Infections in the bile ducts or gallbladder can spread to the liver and cause abscesses.            </li>
            <li><h5>Immunosuppression:</h5>People with weakened immune systems (such as those with HIV/AIDS, diabetes, or undergoing chemotherapy) are more susceptible to infections, including liver abscesses.            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Right Upper Abdominal Pain:</h5>Often sharp, constant pain that may radiate to the right shoulder or back.            </li>
            <li><h5>Fever and Chills:</h5>Fever with chills and sweating is a common symptom, indicating an active infection.            </li>
            <li><h5>Nausea and Vomiting:</h5>Digestive disturbances, including nausea and vomiting, are often associated with liver abscesses.            </li>
            <li><h5>Loss of Appetite and Weight Loss:</h5>Infections in the liver can lead to reduced appetite and subsequent weight loss.            </li>
            <li><h5>Jaundice:</h5>Yellowing of the skin and eyes due to liver dysfunction and buildup of bilirubin in the bloodstream.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Sepsis:</h5>The infection from the liver abscess can spread to the bloodstream, leading to sepsis, a potentially life-threatening condition.            </li>
            <li><h5>Rupture of the Abscess:</h5>If the abscess ruptures, it can lead to peritonitis or an infection in the surrounding abdominal cavity.            </li>
            <li><h5>Pleural Effusion:</h5>In some cases, the infection may spread to the lungs, causing fluid accumulation and difficulty breathing.            </li>
            <li><h5>Shock:</h5>Severe infections can lead to septic shock, characterised by dangerously low blood pressure and organ failure.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Antibiotics or Antiparasitic Medications:</h5>High-dose antibiotics are used to treat pyogenic liver abscesses, while antiparasitic drugs are necessary for amoebic abscesses.            </li>
            <li><h5>Percutaneous Drainage:</h5>A needle is inserted into the abscess to drain the pus, usually guided by ultrasound or CT scan, to relieve pain and help the infection clear up.            </li>
            <li><h5>Surgical Drainage:</h5>In severe cases, surgical drainage may be required to remove the abscess and prevent further complications.            </li>         
            <li><h5>Supportive Care:</h5>Bed rest, hydration, and nutritional support are often necessary during recovery.            </li>
            <li><h5>Liver Function Monitoring:</h5>Regular monitoring of liver enzymes and other liver function tests to assess the recovery and detect any complications.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Hepar Sulphuris Calcareum:</h5>Useful for abscesses that are sensitive to touch, with sharp, splinter-like pain. This remedy is suitable for cases where there is fever, chills, and a tendency to suppuration (formation of pus).            </li>
            <li><h5>Lycopodium:</h5>Effective for liver-related issues with symptoms such as bloating, indigestion, and right-sided abdominal pain that extends to the shoulder. It is beneficial for individuals with poor digestion and gas.            </li>
            <li><h5>Chelidonium Majus:</h5>Recommended for liver abscesses with symptoms like jaundice, dull aching pain in the right upper abdomen, and yellowish skin or sclera. This remedy helps stimulate liver function and supports bile flow.            </li>         
            <li><h5>Silicea:</h5>Helpful in cases where abscesses take a long time to heal or drain. It is particularly useful for chronic infections and helps to expel foreign matter and pus.            </li>
            <li><h5>Mercurius Solubilis:</h5>Suitable for abscesses with fever, excessive sweating, foul breath, and tender liver. This remedy is helpful for individuals with a weakened immune system who are prone to infections.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy can be a supportive approach for individuals with liver abscesses, particularly during the recovery phase or in cases where symptoms are mild. Homoeopathic treatment aims to enhance the immune system, promote healing, and relieve symptoms such as pain, jaundice, and fatigue. While homoeopathy should not replace conventional treatments for severe or acute cases, it serves as a valuable complementary approach for those seeking a natural option to support liver health and prevent recurrences. As always, it’s important to work with a healthcare provider to ensure the best possible outcome.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Liver abscess Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Liver abscess.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/liver-abscess/liver-abscess-diet">Diet for preventing Liver abscess</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Liver abscess</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for relief of Liver abscess</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Liver Abscess Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/fL7IAzcEaVc?si=fx_CMoc6x9QQ6_LP"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
