import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/hh2.jpg')}alt="Urology Hero" />
        <h1>Diabetes mellitus(DM)</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Diabetes mellitus(DM)</h2>
          <img src={require('../images/hormones2.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy views diabetes mellitus as a metabolic disorder involving insulin imbalance and focuses on restoring the body’s natural balance.</li>
            <li>Treatment aims to address underlying causes, manage symptoms, and improve overall health through individualized remedies.</li>
            <li>Remedies are chosen based on the totality of symptoms, considering physical, emotional, and mental aspects of the patient.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Diabetes mellitus type 1 is often autoimmune, where the immune system attacks insulin-producing cells in the pancreas.</li>
            <li>Type 2 diabetes typically involves insulin resistance, where cells become less responsive to insulin.</li>
            <li>Genetic predisposition, lifestyle factors (such as diet and exercise), and environmental influences play significant roles.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic tests include fasting blood sugar levels, oral glucose tolerance tests, and HbA1c levels to assess long-term blood sugar control.</li>
            <li>Homeopaths may also consider symptoms like excessive thirst, frequent urination, and fatigue in their evaluation.</li>
            <li>Physical examination and medical history help in understanding the progression and impact of diabetes on the patient’s health.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications can include cardiovascular disease, neuropathy (nerve damage), nephropathy (kidney damage), and retinopathy (eye damage).</li>
            <li>Risk factors include obesity, sedentary lifestyle, family history of diabetes, and age (type 2 diabetes risk increases with age).</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include insulin therapy (for type 1 diabetes) and medications to improve insulin sensitivity or decrease glucose production (for type 2 diabetes).</li>
            <li>Lifestyle modifications such as diet control, regular exercise, and monitoring blood sugar levels are essential components.</li>
            <li>Regular medical check-ups and adjustments to treatment plans based on blood sugar levels and overall health status.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li>Individualized remedies like Syzygium jambolanum, Uranium nitricum, or Cephalandra indica may be prescribed based on symptomatology.</li>
            <li>Support for improving pancreatic function, enhancing insulin sensitivity, and managing associated symptoms.</li>
            <li>Emphasis on diet management, stress reduction techniques, and lifestyle modifications to support overall health and diabetes management.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy offers a holistic approach to managing diabetes mellitus, aiming to improve insulin function, regulate blood sugar levels, and prevent complications. It can complement conventional treatments but should be integrated under the supervision of a qualified homeopathic practitioner and in coordination with regular medical care. Personalized treatment plans and ongoing monitoring are crucial for effective management and improving quality of life for individuals with diabetes mellitus.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Diabetes mellitus(DM) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Diabetes mellitus(DM) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/hormonal-diseases/diabetes-mellitus/diabetes-mellitus-diet">Diet for Diabetes mellitus(DM)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Diabetes mellitus(DM)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Diabetes mellitus(DM) Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Diabetes mellitus(DM) Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/u-ljG07hyt8?si=4QYPKFI7vPlu9sat"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
