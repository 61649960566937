import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh10.jpg')}alt="Urology Hero" />
        <h1>Anal Fissures</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Anal Fissures</h2>
          <img src={require('../images/gastric10.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <p>
        Homoeopathy treats fatty liver by addressing the individual as a whole, including physical, mental, and emotional factors. The goal is to identify and treat underlying causes, such as poor diet, lifestyle habits, and metabolic imbalances, rather than merely alleviating symptoms. Homoeopathic remedies are selected based on the individual’s specific symptoms and constitution, aiming to reduce fat accumulation in the liver, improve liver function, and support overall well-being.
        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Obesity:</h5>Excess body weight, especially abdominal fat, can contribute to fat buildup in the liver.            </li>
            <li><h5>Alcohol Consumption:</h5>Excessive drinking damages liver cells and leads to fat accumulation and inflammation.            </li>
            <li><h5>Metabolic Syndrome:</h5>Conditions like insulin resistance, diabetes, and high cholesterol are associated with an increased risk of fatty liver.            </li>
            <li><h5>Poor Diet:</h5>A diet high in refined sugars, unhealthy fats, and processed foods can contribute to fat buildup in the liver.            </li>
            <li><h5>Genetics:</h5>Family history may play a role in developing fatty liver, particularly in non-alcoholic fatty liver disease.            </li>
            <li><h5>Medications and Toxins:</h5>Certain medications (like steroids) and toxins can damage the liver, leading to fat accumulation.            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Fatigue:</h5>Persistent tiredness and low energy levels are common in individuals with fatty liver.            </li>
            <li><h5>Right Upper Abdominal Discomfort:</h5>Dull, aching pain or discomfort in the upper right side of the abdomen, where the liver is located.            </li>
            <li><h5>Loss of Appetite:</h5>Fatty liver may lead to reduced appetite and unintentional weight loss.            </li>
            <li><h5>Jaundice (in severe cases):</h5>Yellowing of the skin and eyes, indicating liver dysfunction.            </li>
            <li><h5>Nausea and Indigestion:</h5>Symptoms of poor digestion, bloating, and nausea after meals.            </li>
            <li><h5>Swelling in the Legs and Abdomen (in advanced stages):</h5>Fluid retention may occur as liver function worsens.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Non-Alcoholic Steatohepatitis (NASH):</h5>An advanced form of NAFLD, characterised by inflammation and liver cell damage, which can progress to cirrhosis.            </li>
            <li><h5>Cirrhosis:</h5>Long-term liver damage can lead to scarring (cirrhosis), reducing liver function and increasing the risk of liver failure.            </li>
            <li><h5>Liver Cancer:</h5>Advanced fatty liver disease, particularly NASH, can increase the risk of developing liver cancer.            </li>
            <li><h5>Cardiovascular Disease:</h5>Fatty liver is often associated with an increased risk of heart disease and stroke due to related metabolic conditions.            </li>
            <li><h5>Liver Failure:</h5>In advanced cases, fatty liver disease can lead to complete liver failure, which is life-threatening and requires a liver transplant.            </li>
          </ul>
            <h3>Traditional Basic Treatmen</h3>
          <ul>
            <li><h5>Lifestyle Changes:</h5>Weight loss, regular exercise, and a balanced diet low in sugar, processed foods, and unhealthy fats are essential for managing fatty liver.            </li>
            <li><h5>Medications:</h5>While no specific medications exist for fatty liver, doctors may prescribe drugs to manage underlying conditions like diabetes, high cholesterol, or high blood pressure.            </li>
            <li><h5>Alcohol Cessation:</h5>Avoiding alcohol is crucial for individuals with alcoholic fatty liver disease to prevent further liver damage.            </li>         
            <li><h5>Vitamin E and Antioxidant Supplements:</h5>Vitamin E and other antioxidants may help reduce liver inflammation, though they should be used under medical supervision.            </li>
            <li><h5>Regular Monitoring:</h5>Individuals with fatty liver disease should have routine blood tests and imaging studies to monitor liver function and progression.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Chelidonium Majus:</h5>Effective for liver issues with symptoms such as a swollen liver, jaundice, right-sided abdominal pain, and indigestion. It supports bile flow and is beneficial for individuals with poor digestion.            </li>
            <li><h5>Carduus Marianus:</h5>Known as a liver tonic in homoeopathy, it is helpful for fatty liver with symptoms like a heavy, sore feeling in the liver, nausea, and bitter taste in the mouth.            </li>
            <li><h5>Nux Vomica:</h5>Suitable for individuals with a history of alcohol consumption or indulgence in rich, fatty foods. It helps with symptoms like indigestion, nausea, bloating, and irritability.            </li>         
            <li><h5>Phosphorus:</h5>Recommended for fatty liver associated with fatigue, tenderness in the liver area, and symptoms of jaundice. It’s especially helpful for individuals with a tendency to bleeding and digestive issues.            </li>
            <li><h5>Lycopodium:</h5>Ideal for individuals with bloating, gas, and right-sided abdominal pain that worsens in the evening. It’s beneficial for those who experience digestive disturbances, especially after consuming fatty foods.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy can offer gentle, homeopathic medicine fatty liver for supportive treatment for individuals with fatty liver by addressing the root causes and promoting long-term liver health. Many patients report improved energy levels, better digestion, and reduced discomfort with homoeopathic remedies. While homoeopathy may not replace conventional treatments for advanced cases, it serves as a valuable complementary approach that can help manage symptoms and prevent progression, especially for those seeking natural options for liver health and overall wellness.          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Anal Fissures Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Anal Fissures.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/fatty-liver/fatty-liver-diet">Diet for preventing Anal Fissures</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Anal Fissures</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for relief of Anal Fissures</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Fatty Liver Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/nnqtuvefxqE?si=ABbfSruv20fX0fK6"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
