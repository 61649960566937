import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Blogs.css';

const blogData = [
  {
    title: 'Introduction to Homeopathy',
    description: 'Discover how homeopathy uses natural remedies to stimulate the body’s healing processes, offering a gentle and holistic alternative to conventional medicine.',
    image: 'blog1.jpg',
    link: '/blogs/homeopathy'
  },          
  {
    title: 'Common Ailments Treated by Homeopathy',
    description: 'Explore how homeopathy effectively addresses common conditions like colds, headaches, and digestive issues using natural, individualized treatments.',
    image: 'blog2.jpg',
    link: '/blogs/founder'
  },
  {
        title: 'Homeopathy for Chronic Diseases: A Holistic Approach',
        description: 'Learn how homeopathy provides long-term relief for chronic conditions such as arthritis, asthma, and allergies by promoting overall wellness.',
        image: 'blog3.jpg',
        link: '/blogs/homeopathy-info'
      },
      {
        title: 'Why Choose Homeopathy Over Conventional Medicine?',
        description: 'Understand the advantages of homeopathy, which focuses on individualized care, minimal side effects, and treating the root cause of illness.',
        image: 'blog4.jpg',
        link: '/blogs/mental-health'
      },
      {
        title: 'Homeopathy for Stress and Anxiety: Natural Healing Solutions',
        description: 'Discover how homeopathy offers safe, non-addictive remedies for managing stress and anxiety, balancing mental and emotional health naturally.',
        image: 'blog5.jpg',
        link: '/blogs/modern-homeopathy'
      },
      {
        title: 'Homeopathy for Skin Problems: Acne, Eczema, and More',
        description: 'Learn how homeopathy treats skin conditions like acne and eczema by addressing underlying causes and promoting healthy skin from within.',
        image: 'blog6.jpg',
        link: '/blogs/homeopathy-vs-allopathy'
      },
      {
        title: 'How Homeopathy Helps Boost Immunity',
        description: 'Explore how homeopathic remedies strengthen the immune system, helping to prevent infections and support the body’s natural defenses.',
        image: 'blog7.jpg',
        link: '/blogs/boost-immunity'
      },
      {
        title: 'Homeopathy for Digestive Disorders: Healing from the Inside',
        description: 'Learn how homeopathy treats digestive issues like indigestion, IBS, and constipation by addressing both symptoms and root causes naturally.',
        image: 'blog8.jpg',
        link: '/blogs/digestive'
      },
      {
        title: 'The Role of Homeopathy in Pediatric Care',
        description: 'Learn how homeopathy offers safe and gentle treatments for children, addressing common issues like teething, colic, and behavioral problems.',
        image: 'blog9.jpg',
        link: '/blogs/pediatric-care'
      },
      {
        title: 'Homeopathic Remedies for Seasonal Allergies',
        description: 'Discover how homeopathy helps manage seasonal allergies by reducing symptoms and strengthening the body’s response to allergens.',
        image: 'blog10.jpg',
        link: '/blogs/seasonal-allergies'
      },
      {
        title: 'Homeopathic Remedies for Joint Pain and Arthritis',
        description: 'Find out how homeopathy offers natural relief for joint pain and arthritis by reducing inflammation and improving mobility.',
        image: 'blog11.jpg',
        link: '/blogs/joint-pain'
      },
      {
        title: 'Debunking Myths About Homeopathy',
        description: 'Address common myths and misconceptions about homeopathy, clarifying how it works and its proven effectiveness.',
        image: 'blog12.jpg',
        link: '/blogs/myth'
      },
];

const Blogs = () => {
  return (
    <div className="blogs-page">
      <div className="blogs-hero-section">
      <div className='blogs-new'>
      <h1>Blogs</h1>
      <h2>Homeopathy is the latest and refined</h2>
      <h2>method of treating patients</h2>
      <h2>economically and non-violently</h2>
      </div>
        {/* <img src={require('../images/blog_hero.jpg')} alt="Blogs Hero" className="blogs-hero-img" /> */}
      </div>

      <div className="blogs-container">
        {blogData.map((blog, index) => (
          <div className="blog-card" key={index}>
            <img src={require(`../images/${blog.image}`)} alt={blog.title} className="blog-img" />
            <div className="blog-content">
              <h3>{blog.title}</h3>
              <p>{blog.description}</p>
              <Link to={blog.link} className="learn-more-btn">Learn More</Link>
            </div>
          </div>
        ))}
      </div>

      <div className="contact-map-row">
        <div className="map-card">
          <h3>Our Location</h3>
          <iframe
            title="Niramaya Homeopathy Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114008.58764245966!2d83.31226966300316!3d26.751766994497036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39914486bfb2c0e9%3A0xaf54983a8579801c!2sDr%20Atul%20kumar%20singh%20(BHMS%2C%20MD%2C%20PG%20London)%20Homeopath!5e0!3m2!1sen!2sin!4v1728539308354!5m2!1sen!2sin"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="contact-us-card">
          <h3>Contact Us</h3>
          <p>For inquiries and appointments, please contact us:</p>
          <p><strong>Phone:</strong> <a href="tel:+919236185711" className="blogs-link">+919236185711</a></p>
          <p><strong>Email:</strong> <a href="mailto:niramayaforyou@gmail.com" className="blogs-link">niramayaforyou@gmail.com</a></p>
          <p><strong>Address:</strong> P9RG+WV3, Cantt Rd, Bansgaon Colony, Kalepur, Gorakhpur, Uttar Pradesh 273001</p>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
