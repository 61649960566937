import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh4.jpg')}alt="Urology Hero" />
        <h1>Acne</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Acne</h2>
          <img src={require('../images/skin4.jpg')}alt="Urology Hero" />
          <p>
          Acne vulgaris, commonly referred to as acne, is a chronic inflammatory skin condition that affects the pilosebaceous units (hair follicles and oil glands) of the skin. It is characterized by various forms of lesions, including comedones (blackheads and whiteheads), papules, pustules, nodules, and cysts. Acne typically appears on the face, chest, back, and shoulders and can range from mild to severe, impacting both physical appearance and emotional well-being.          </p>
          <h3>Epidemiology of Acne</h3>
          <p>Acne is one of the most prevalent dermatological conditions worldwide, affecting individuals of all ages but primarily adolescents and young adults:</p>
          <ul>
            <li><h5>Prevalence:</h5>Estimated to affect around 9.4% of the global population.</li>
            <li><h5>Age Distribution:</h5>Peak incidence during adolescence, affecting up to 85% of individuals aged 12-24 years.</li>
            <li><h5>Gender Differences:</h5>More prevalent in males during adolescence, but persists longer and is more common in females in adulthood.</li>
          </ul>
          <h3>Causes of Acne</h3>
          <p>The development of acne involves a complex interplay of factors, including:</p>.
          <ul>
            <li><h5>Sebum Production:</h5>Overproduction of sebum (skin oil) by sebaceous glands.</li>
            <li><h5>Hair Follicle Clogging: </h5>Accumulation of dead skin cells and sebum within hair follicles.</li>
            <li><h5>Bacterial Infection:</h5>Propionibacterium acnes (P. acnes) bacteria within hair follicles.</li>
            <li><h5>Inflammation:</h5>Immune response to P. acnes bacteria and other triggers.</li>
            <li><h5>Hormonal Factors:</h5>Androgens (e.g., testosterone) play a significant role in stimulating sebum production, often explaining why acne worsens during puberty.</li>
          </ul>
          <h3>Signs and Symptoms of Acne</h3>
          <p>Acne manifests through various types of lesions and symptoms:</p>
          <ul>
            <li><h5>Comedones:</h5>Non-inflammatory lesions, including blackheads (open comedones) and whiteheads (closed comedones).</li>
            <li><h5>Inflammatory Lesions:</h5> Red papules, pustules (pus-filled lesions), nodules (large, painful solid lesions), and cysts (deep, inflamed, pus-filled lesions).</li>
            <li><h5>Scarring:</h5>Post-inflammatory hyperpigmentation (dark spots) and atrophic or hypertrophic scars.</li>
          </ul>
          <h3>Allopathic Medicines and Treatments</h3>
          <p>Allopathic treatments for acne aim to reduce sebum production, prevent follicular plugging, and control inflammation:</p>
          <ul>
            <li><h5>Topical Treatments:</h5>Benzoyl peroxide, retinoids (e.g., adapalene, tretinoin), and antibiotics (e.g., clindamycin, erythromycin).</li>
            <li><h5>Systemic Medications: </h5>Oral antibiotics (e.g., doxycycline, minocycline), oral contraceptives (for females with hormonal acne), and isotretinoin (for severe nodular or cystic acne).</li>
            <li><h5>Procedures:</h5>Extraction of comedones, intralesional corticosteroid injections (for nodules and cysts), and laser or light therapy.</li>
          </ul>
          <h3>Prognosis and Precautions</h3>
          <p>The prognosis of acne varies depending on its severity and the effectiveness of treatment:</p>
          <ul>
            <li><h5>Mild Acne:</h5>Typically responds well to topical treatments with minimal scarring.</li>
            <li><h5>Moderate to Severe Acne:</h5>May require systemic medications and can lead to scarring if untreated.</li>
            <li><h5>Scarring:</h5>Prevention through early and effective treatment is crucial to minimize permanent scarring.</li>
          </ul>
          <p>Precautions include:</p>
          <ul>
            <li><h5>Avoiding Skin Irritants: </h5>Using non-comedogenic (non-pore-clogging) skincare products.</li>
            <li><h5>Sun Protection: </h5>Using sunscreen to prevent exacerbation of post-inflammatory hyperpigmentation.</li>
            <li><h5>Avoiding Squeezing or Picking:</h5>Minimizing trauma to the skin to prevent scarring.</li>
          </ul>
          <h3>Homeopathic Treatments for Acne</h3>
          <p>Homeopathy offers alternative treatments that aim to address underlying imbalances and promote skin health:</p>
          <ul>
            <li><h5>Sulfur:</h5>For acne with redness and itching, aggravated by warmth.</li>
            <li><h5>Hepar Sulphuris Calcareum: </h5>For painful acne lesions that are sensitive to touch.</li>
            <li><h5>Kali Bromatum:</h5>For acne with deep-seated pustules and nodules.</li>
          </ul>
          <p>Acne is a widespread dermatological condition with significant implications for affected individuals’ quality of life. Understanding its epidemiology, causes, symptoms, and treatment options, including allopathic and homeopathic approaches, is essential for effective management. Allopathic treatments focus on reducing inflammation, controlling sebum production, and preventing scarring, while homeopathy offers alternative remedies aiming to restore balance and promote skin healing. Personalized care and early intervention are crucial for achieving favorable outcomes and minimizing the long-term impact of acne on both physical and emotional well-being.</p>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy provides a natural and comprehensive approach to managing kidney stones, focusing on symptom relief, facilitating stone passage, preventing recurrence, and promoting overall kidney health. Our clinical experience indicates that homeopathic treatment can effectively alleviate acute pain, aid in the passage of stones, and reduce the frequency of stone formation.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Acne Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Acne.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>
          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/acne/acne-diet">Diet for Acne prevention</a></li>
              <li> <a href="#" onClick={(e) => e.preventDefault()}>Prevention of Acne</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Acne</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Acne Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/PHzDr_LwtPY?si=bfovXuVmZ2mZiK-W"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
