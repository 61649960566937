import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia5.jpg')}alt="Urology Hero" />
        <h1>Developmental disorder Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Developmental disorder</h2>
          <img src={require('../images/child5.jpg')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy offers a holistic approach to treating developmental disorders, focusing on the individual’s physical, emotional, and psychological well-being. The treatment aims to address underlying causes rather than just managing symptoms, utilizing remedies selected based on the principle of “like cures like.” This involves understanding the unique symptoms and constitution of each patient to stimulate the body’s natural healing process.
          </p>
          <h3>Causes</h3>
          <p>Developmental disorders can stem from various factors:</p>
          <ul>
            <li><h5>Genetic Factors:</h5> Inherited traits or genetic mutations influencing development.</li>
            <li><h5>Neurological Factors:</h5> Structural or functional abnormalities in the brain affecting cognitive and motor skills.</li>
            <li><h5>Environmental Factors:</h5> Prenatal exposure to toxins, infections, or inadequate nutrition.</li>
            <li><h5>Prenatal and Birth Complications:</h5> Premature birth, low birth weight, or birth trauma.</li>
            <li><h5>Social and Emotional Factors:</h5> Early childhood experiences, neglect, or trauma impacting development.</li>
          </ul>
          <h3>Investigations</h3>
          <p>Diagnosing developmental disorders involves a comprehensive assessment:</p>
          <ul>
            <li><h5>Medical History: </h5>Detailed review of developmental milestones, medical conditions, and family history.</li>
            <li><h5>Developmental Assessments:</h5> Observations and standardized tests to evaluate cognitive, motor, and social skills.</li>
            <li><h5>Psychological Evaluations: </h5> Assessments to understand emotional and cognitive strengths and weaknesses.</li>
            <li><h5>Neurological Tests:</h5> Imaging and diagnostic tests to identify structural or functional brain abnormalities.</li>
            <li><h5>Genetic Testing:</h5> To detect genetic conditions associated with developmental disorders.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>Developmental disorders can lead to various challenges:</p>
          <ul>
            <li><h5>Academic and Learning Difficulties:</h5> Trouble with learning, attention, and academic performance.</li>
            <li><h5>Social and Behavioral Issues:</h5> Difficulty in forming relationships, behavioral problems, and emotional regulation.</li>
            <li><h5>Sensory Sensitivities:</h5> Heightened or diminished responses to sensory stimuli.</li>
            <li><h5>Coexisting Mental Health Conditions:</h5> Increased risk of anxiety, depression, or other psychiatric disorders. Risk factors include genetic predisposition, prenatal complications, environmental influences, and social factors impacting early development.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatment strategies include:</p>
          <ul>
            <li><h5>Behavioral Therapy:</h5> Applied Behavior Analysis (ABA) to improve behavior and social skills.</li>
            <li><h5>Speech and Language Therapy:</h5> Enhancing communication skills, both verbal and non-verbal.</li>
            <li><h5>Occupational Therapy:</h5> Developing fine motor skills, adaptive behaviors, and sensory integration.</li>
            <li><h5>Physical Therapy: </h5>Improving gross motor skills, coordination, and physical abilities.</li>
            <li><h5>Medication:</h5> Managing coexisting conditions like anxiety, depression, or attention deficits, although no specific medication targets developmental disorders directly.</li>
            <li><h5>Educational Support:</h5> Specialized education plans (IEPs) and educational interventions tailored to individual needs.</li>
            <li><h5>Family and Support Services: </h5> Counseling, parent training, and support groups to aid families in understanding and managing developmental challenges.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <p>Homeopathy offers personalized remedies based on individual symptoms and constitution:</p>
          <ul>
            <li><h5>Calcarea Carbonica:</h5> For delayed milestones, slow development, and sensitivity to cold.</li>
            <li><h5>Baryta Carbonica: </h5> For developmental delays, shyness, and difficulty in social interactions.</li>
            <li><h5>Silicea: </h5> For timidity, lack of confidence, and susceptibility to infections.</li>
            <li><h5>Carcinosin:</h5> For perfectionism, sensitivity to criticism, and emotional intensity.</li> 
            <li><h5>Stramonium:</h5> For fears, aggression, and restlessness, especially in unfamiliar situations.</li>
            <li><h5>Natrum Muriaticum:</h5> For emotional sensitivity, introversion, and difficulty expressing emotions.</li>
            </ul>
            <p>Homeopathic remedies are administered in highly diluted forms, tailored to address specific symptoms and overall health. Consulting with a qualified homeopath ensures accurate remedy selection and appropriate dosage adjustments over time.</p>
          <h3>Conclusion</h3>
          <p>In our experience, integrating homeopathy into a comprehensive treatment approach enhances outcomes for individuals with developmental disorders. Patients often achieve improved communication, social interactions, and overall well-being. Homeopathy’s gentle yet effective nature complements traditional therapies, supporting holistic health and long-term development. Each treatment plan is personalized to address the unique needs of the individual, promoting resilience, and fostering a balanced quality of life.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Developmental disorder Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Developmental disorder conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/developmental-disorder/developmental-disorder-diet">Diet for Developmental disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Developmental disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Developmental disorder</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Developmental disorder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/psfqNnhs3nI?si=WNPKU0D39Xbw436Z"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
