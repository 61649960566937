import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/Footer.css'; // Custom CSS for Footer
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
 
const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-2">
      <Container>
        <Row className="footer-row">
          {/* About Us Column */}
          <Col md={3} xs={12}>
            <h4>About Us</h4>
            <p>
              We provide expert homeopathic treatments and personalized healthcare solutions to ensure a healthy life. Our doctors are here to guide you through your recovery with the best treatment options.
            </p>
          </Col>
 
          {/* Quick Links Column */}
          <Col md={3} xs={12}>
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li><a href="/" className="footer-link">Home</a></li>
              <li><a href="/about" className="footer-link">About Us</a></li>
              <li><a href="/treatments" className="footer-link">Treatments</a></li>
              <li><a href="/contact-US" className="footer-link">Contact Us</a></li>
            </ul>
          </Col>
 
          {/* Contact Us Column */}
          <Col md={3} xs={12}>
            <h4>Contact Us</h4>
            <p>
              Phone: <a href="tel:+919236185711" className="footer-link">+919236185711</a>
            </p>
            <p>
              Email: <a href="mailto:niramayaforyou@gmail.com" className="footer-link">niramayaforyou@gmail.com</a>
            </p>
            <p>Address: 0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar Pradesh, India, 273001</p>
          </Col>
 
          {/* Social Media Icons */}
          <Col md={3} xs={12} className='s-icons'>
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href="https://www.facebook.com/doctoratulsingh" target="_blank" rel="noopener noreferrer" style={{ color: '#1478fa', margin: '0 4px' }} className='so-icons'>
                <FontAwesomeIcon icon={faFacebook} size="1x" />
              </a>
              <a href="https://www.youtube.com/@dratulkumarsingh" target="_blank" rel="noopener noreferrer" style={{ color: '#fc3a3a', margin: '0 4px' }} className='so-icons'>
                <FontAwesomeIcon icon={faYoutube} size="1x" />
              </a>
              <a href="https://www.instagram.com/niramaya_homoeopathy01/" target="_blank" rel="noopener noreferrer" style={{ color: '#ed15be', margin: '0 4px' }} className='so-icons'>
                <FontAwesomeIcon icon={faInstagram} size="1x" />
              </a>
              <a href="https://www.linkedin.com/company/niramaya-homoeopathy/" target="_blank" rel="noopener noreferrer" style={{ color: '#0293de', margin: '0 4px' }} className='so-icons'>
                <FontAwesomeIcon icon={faLinkedin} size="1x" />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={11} className="text-center">
            <p>&copy; Digiquest Consultancy Services Pvt Ltd. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
 
export default Footer;
 