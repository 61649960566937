import React from 'react';
import {Link} from 'react-router-dom';
import '../css/Urology.css'; // Include your custom CSS here
// import Kidney from '../components/Kidney';

const UrologyPage = () => {
  return (
    <div className="urology-page">
      {/* Hero Image */}
      <div className="uro-hero-image">
        <img src={require('../images/treat1.jpg')}alt="Urology Hero" />
       
      </div>

      {/* Main content section */}
      <div className="uro-content-section">
        {/* Disease Links */}
        <div className="disease-links">
          <h1>Related Urological Diseases</h1>
          <ul>
            <li><Link to="/treatments/urology/kidney-stone">Kidney Stone</Link></li>
            <li><Link to="/treatments/urology/urethral-stricture">Urethral Stricture</Link></li>
            <li><Link to="/treatments/urology/bph-prostate">BPH (Prostate)</Link></li>
            <li><Link to="/treatments/urology/bladder-polyps">Bladder Polyps</Link></li>
            <li><Link to="/treatments/urology/overactive-bladder">Overactive Bladder</Link></li>
            <li><Link to="/treatments/urology/uti">UTI (Urinary Tract Infection)</Link></li>
            <li><Link to="/treatments/urology/crf-management">CRF Management</Link></li>
            <li><Link to="/treatments/urology/chronic-cystitis">Chronic Cystitis</Link></li>
            <li><Link to="/treatments/urology/acute-renal-failure">Acute Renal Failure (ARF)</Link></li>
          </ul>
        </div>

        {/* Right Side Content */}
        <div className="uro-right-content">
          <div className="uro-search-box">
            <h3>Search</h3>
            <div className="uro-search-bar">
              <input type="text" placeholder="Search..." />
              <button>Search</button>
            </div>
          </div>
          <div className="recent-posts">
            <h3>Recent Posts</h3>
            <ul>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathic medicine doctors near you for Urology</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Best Diet for Kidney Health</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>FAQs about Urological Diseases</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Treatment Options for BPH</a></li>
            </ul>
          </div>
        </div>
      </div>
       <div className="uro-contact-us-card">
        <h2>Contact Us</h2>
        <p>
          Have any questions about urological health? Feel free to get in touch with us for expert advice, consultation, or to book an appointment.
        </p>
        <p>
          📞 Phone: <a href="tel:+919236185711">+919236185711</a><br />
          📧 Email: <a href="mailto:niramayaforyou@gmail.com">niramayaforyou@gmail.com</a><br />
          📍 Address: 0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar Pradesh, India, 273001
        </p>
        <button className="appointment-btn">Book Appointment</button>
      </div>
    </div>
  );
};

export default UrologyPage;
