import React from 'react';
import '../../css/CaseStudy.css';

const FemaleHealthCaseStudy = () => {
  return (
    <div className="case-study-page">
      <div className="case-study-hero">
        <h1>Balancing Female Health with Homeopathy: Addressing Reproductive Health Issues Naturally</h1>
        {/* <img src={require('../images/case-study-hero.jpg')} alt="Female Health Care" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Priya, a 29-year-old from Bangalore, India, had been dealing with several reproductive health issues, including irregular menstrual cycles, hormonal imbalances, and severe PMS symptoms. Conventional treatments provided only temporary relief, and she was concerned about potential side effects. In early 2021, she decided to try homeopathic care, hoping for a natural and holistic approach to managing her symptoms.
        </p>

        <h2>Starting the Journey with Homeopathy</h2>
        <p>
          During her initial consultation, Priya’s homeopathic doctor took a comprehensive history, discussing her symptoms, lifestyle, and emotional well-being. Based on this assessment, a personalized treatment plan was created to address her hormonal imbalances, regulate her menstrual cycle, and alleviate PMS symptoms.
        </p>

        <h2>A Holistic and Gentle Approach</h2>
        <p>
          Alongside her homeopathic remedies, Priya’s doctor recommended lifestyle adjustments, including stress management practices, dietary modifications, and gentle exercises to support hormonal balance. This holistic approach aimed to address the root causes of her symptoms and promote overall reproductive health.
        </p>

        <h2>Progress in the First Few Months</h2>
        <p>
          Within the first few months of treatment, Priya began to experience a reduction in PMS symptoms, and her cycles started to become more regular. She also felt an improvement in her energy levels and mood, which motivated her to continue with the treatment and recommended lifestyle practices.
        </p>

        <h2>Significant Improvements Over the First Year</h2>
        <p>
          By the end of the first year, Priya’s menstrual cycles were consistently regular, and her PMS symptoms had lessened considerably. Her hormonal levels were stabilizing, which positively impacted her overall health. Her doctor continued to monitor her progress and made minor adjustments to her treatment as needed.
        </p>

        <h2>Achieving Hormonal Balance and Improved Well-Being</h2>
        <p>
          After a year and a half of consistent homeopathic care, Priya achieved significant relief from her symptoms. Her menstrual cycle was regular, her PMS symptoms were minimal, and she felt a renewed sense of well-being. Her doctor gradually reduced her remedies, and Priya found she could maintain her health with minimal support.
        </p>

        <h2>Living with Enhanced Reproductive Health</h2>
        <p>
          Today, Priya enjoys balanced reproductive health and a stable, positive outlook. Homeopathy not only helped alleviate her symptoms but also encouraged lifestyle changes that she continues to follow. Her journey demonstrates the potential of homeopathy to address female health concerns safely and effectively, promoting balance and vitality.
        </p>

        <h2>Final Thoughts</h2>
        <p>
          Priya’s experience highlights the effectiveness of homeopathy in managing female reproductive health issues. Her story serves as an inspiring example for other women seeking a natural, personalized approach to reproductive health, proving that homeopathy can be a viable and empowering option.
        </p>
      </div>
    </div>
  );
};

export default FemaleHealthCaseStudy;
